import { AppElement, html } from '../AppElement.js';
import TableLoadingRow from '../table/TableLoadingRow';
import TableNoDataRow from '../table/TableNoDataRow';
import TableColumn from '../table/TableColumn';
import TableCell, { tableCellTypes } from '../table/TableCell';
import Constants from '../Constants';
import TableRow from '../table/TableRow';
import MiniContext from '../miniContext/MiniContext';
import VirtualAnalyst from '../virtualAnalayst/VirtualAnalyst';
import { isJSONString } from '../Utils';
import ColorArray from '../ColorArray';
import { getIdentityDependantTableStateKey } from '../TableUtils';
import Table from '../table/Table';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement';
import { sendRequest } from '../App.common';
import { mapCompPacePerSetRank, mapRatePacePerSetRank } from '../StoryPitchMappings';

// Additionally, there will be dynamic columns for the competitive set names.
export const cellFieldMap = {
    stayDate: 'stayDate',
    notes: 'notes',
    roomsToSell: 'roomsToSell',
    roomsOtb: 'roomsOtb',
    occupancy: 'occupancy',
    revenue: 'revenue',
    adr: 'adr',
    roomsOtbVsLastYear: 'roomsOtbVsLastYear',
    revenueVsLastYear: 'revenueVsLastYear',
    adrVsLastYear: 'adrVsLastYear',
    roomsOtbPickup: 'roomsOtbPickup',
    revenuePickup: 'revenuePickup',
    pickupsAdr: 'pickupsAdr',
    adrPickup: 'adrPickup',
    roomsOtbPace: 'roomsOtbPace',
    ratePace: 'ratePace',
    availabilityPace: 'availabilityPace',
    compSetAvgPace: 'compSetAvgPace',
    forecast: 'forecast',
    fcrange: 'fcrange',
    expectedPickup: 'expectedPickup',
    positioning: 'positioning',
    comppricing: 'comppricing',
    mypricing: 'mypricing',
    details: 'details',
    rateguidance: 'rateguidance',
    ratedecision: 'ratedecision',
    housekeepers: 'housekeepers',
    rate: 'rate',
    ratePickup: 'ratePickup',
    lastratechange: 'lastratechange',
    compSetAvg: 'compSetAvg',
    PriceAndPosition: 'PriceAndPosition'
};

let requestCount = 0; // Use this to make sure that only the latest ajax request can change internal properties.

export default class PageDesktopSingleTable extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number },
            startDate: { type: String },
            endDate: { type: String },
            dayofweekfilter: { type: String },

            // @internalProperty
            tableOpts: { attribute: false },
            columns: { attribute: false },
            rows: { attribute: false },
            isLoading: { attribute: false }
        };
    }

    constructor(props = {}) {
        super();
        this.dayofweekfilter = props.dayofweekfilter || this.dayofweekfilter;
        this.hotel = props.hotel || this.hotel || window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;

        this.tableStateKey = getIdentityDependantTableStateKey('PageDesktopSingleTable');
        this.tableOpts = {
            ...Table.getDefaultTableOpts(),
            isStickyHeader: true,
            stickyHeaderMinHeight: '74vh'
        };
        this.columns = this.makeStaticColumns();
        this.rows = [];
        this.isLoading = false;
        this.tableCellCache = new Map();
        this.roomsPerHousekeeper = 0;
        this.openAiRateGuidance = '';

        /** *****MY-PRICE AND COMP-PRICE FROM VIRTUAL ANALYSIS****** */
        this.numTimeLeadCloseDownChange = Constants.MISSING_STR;
        this.numTimeLeadCloseLength = Constants.MISSING_STR;
        this.leaderCloserListItems = [];
        this.dropIncreasePercentAnalysis = Constants.MISSING_STR;
        this.leadTime = Constants.MISSING_STR;
        this.dow = Constants.MISSING_STR;
        this.dropIncreaseAverageAnalysis = Constants.MISSING_STR;
        this.dropIncreasePercent7Days = Constants.MISSING_STR;
        this.dropIncreasePercent7DaysTxt = Constants.MISSING_STR;
    }

    reflow(props = {}) {
        this.hotel = props.hotel || this.hotel;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
        this.startDate = props.startDate || this.startDate;
        this.endDate = props.endDate || this.endDate;
        this.dayofweekfilter = props.day || this.dayofweekfilter;
    }


    makeStaticColumns() {
        const columnKeyToStringMap = {
            [cellFieldMap.stayDate]: Constants.STRINGS.STAY_DATE,
            [cellFieldMap.notes]: 'Notes',
            [cellFieldMap.roomsToSell]: 'Rms to sell',
            [cellFieldMap.expectedPickup]: 'Expected PU',
            [cellFieldMap.occupancy]: 'Occupancy',
            [cellFieldMap.forecast]: 'Forecast',
            [cellFieldMap.fcrange]: 'FC Range',
            [cellFieldMap.roomsOtbPace]: 'My Pace',
            [cellFieldMap.roomsOtb]: 'Rms',
            [cellFieldMap.adr]: 'ADR',
            [cellFieldMap.revenue]: 'Rev',
            [cellFieldMap.roomsOtbVsLastYear]: 'Rms vs LY',
            [cellFieldMap.adrVsLastYear]: 'ADR vs LY',
            [cellFieldMap.revenueVsLastYear]: 'Rev vs LY',
            [cellFieldMap.roomsOtbPickup]: 'PU Rms',
            [cellFieldMap.pickupsAdr]: 'PU ADR',
            [cellFieldMap.revenuePickup]: 'PU Rev',
            [cellFieldMap.adrPickup]: 'ADR Move',
            [cellFieldMap.details]: 'Details',
            [cellFieldMap.positioning]: 'Positioning',
            [cellFieldMap.rate]: 'Rate',
            [cellFieldMap.mypricing]: 'My Pricing',
            [cellFieldMap.ratePickup]: 'Rate Change', /* Rate PU */
            [cellFieldMap.lastratechange]: 'Last Rate Change',
            [cellFieldMap.compSetAvg]: 'Comp Avg',
            [cellFieldMap.comppricing]: 'Comp Pricing',
            [cellFieldMap.rateguidance]: 'Rate Guidance',
            [cellFieldMap.ratedecision]: 'Rate Decision'
        };
        let columns = [];
        Object.keys(columnKeyToStringMap)
            .forEach(key => {
                columns.push(new TableColumn(
                    new TableCell({
                        field: key,
                        value: columnKeyToStringMap[key],
                        type: tableCellTypes.TH
                    }),
                    [],
                    undefined,
                    null,
                    true,
                    false
                ));
            });
        return columns;
    }

    addCompNameColumn(dynamicColumnKey, dynamicColumnText) {
        const existing = this.columns.find(column => column.tableCell.field === dynamicColumnKey);
        if (existing) {
            return;
        }

        // Sync.
        const newColumn = new TableColumn(
            new TableCell({
                field: dynamicColumnKey,
                value: dynamicColumnText,
                type: tableCellTypes.TH
            }),
            [],
            undefined,
            null,
            true,
            false
        );
        this.columns = [...this.columns, newColumn];

        // Async.
        $.when(window.infinito.vao.controller.compSetMappingHelper.fetchCompMapForCompetitor(dynamicColumnText))
            .then(mapName => {
                if (mapName && mapName !== dynamicColumnText) {
                    newColumn.tableCell.value = mapName;
                    this.columns = [...this.columns];
                }
            });
    }

    onClickStayDate(stayDate, e) {
        const prettyRecordDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            this.recordDate
        );
        const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
            stayDate
        );
        const prettyPickupSetting = window.infinito.vao.controller.pickupHelper.getPickupOffsetTextForPickupOffset(
            this.pickupSetting
        );

        window.infinito.components.basicModal.render({
            title: $('<div></div>')
                .append('<h5>Virtual Analyst</h5>')
                .append(new MiniContext({
                    recordDate: prettyRecordDate,
                    stayDate: prettyStayDate,
                    pickupSetting: prettyPickupSetting,
                    isModalStyle: false
                })),
            isTitleHtml: true,
            bodyElem: $(new VirtualAnalyst({
                recordDate: this.recordDate,
                stayDate: stayDate,
                pickupSetting: this.pickupSetting,
                hotel: this.hotel
            }))
        });
    }

    onClickDetails(e, stayDate) {
        let body = '<vao-page-businessdetails-modal stayDate="' + e + '"></vao-page-businessdetails-modal>';

        let stay_date = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(e);
        stay_date = `<h5>Business Details - ${stay_date}</h5>`;
        let $title = $('<div></div>')
            .append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size: 'lg'
        });
    }

    onClickNotes(e, hotels_id, stayDate, rowCells) {
        let body = '<vao-modal-notes-modal stayDate="' + e + '" hotel_id="' + hotels_id + '" rowCells="' + rowCells + '"></vao-modal-notes-modal>';

        let stay_date = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(e);
        stay_date = `<h5>Notes - ${stay_date}</h5>`;
        let $title = $('<div></div>')
            .append(stay_date);
        window.infinito.components.basicModal.render({
            title: $title,
            isTitleHtml: true,
            bodyElem: $(body),
            size: 'lg'
        });
    }

    acquireTableCell(stayDate, props) {
        const key = `__@${props.field}_${stayDate}__`;
        if (this.tableCellCache.has(key)) {
            let existingTableCell = this.tableCellCache.get(key);
            existingTableCell.reflow(props);
            return existingTableCell;
        }
        let newTableCell = new TableCell(props);
        this.tableCellCache.set(key, newTableCell);
        return newTableCell;
    }

    makeGradientColorCoderForMinAndMax(min, max) {
        const colorBuckets = window.infinito.vao.controller.gradientColorHelper.getColorBucketsForMinForMax(min, max);
        return function (statisticValue) {
            if (!$.isNumeric(statisticValue)) {
                return null;
            }
            const floatValue = parseFloat(statisticValue);
            if (floatValue === 0 || floatValue === 0.0) {
                return null;
            }
            return window.infinito.vao.controller.gradientColorHelper.getColorForValueForColorBuckets(
                floatValue,
                colorBuckets,
                null
            );
        };
    }

    makeGradientColorCoder(procData, key) {
        let values = [];
        let min;
        let
            max;
        if (
            procData
            && typeof procData === 'object'
            && procData[key]
            && typeof procData[key] === 'object'
        ) {
            Object.values(procData[key])
                .forEach(statistic => {
                    if (
                        statistic
                        && typeof statistic === 'object'
                        && 'value' in statistic
                        && $.isNumeric(statistic.value)
                    ) {
                        values.push(parseFloat(statistic.value));
                    }
                });
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }

    makeAdrMoveGradientColorCoder(procData) {
        return this.makeGradientColorCoder(procData, 'adrPickup');
    }

    makeCompSetAvgGradientColorCoder(procData) {
        let values = [];
        let min;
        let
            max;
        if (
            procData
            && typeof procData === 'object'
            && procData.rate
            && typeof procData.rate === 'object'
            && procData.compAverageRate
            && typeof procData.compAverageRate === 'object'
        ) {
            Object.keys(procData.rate)
                .forEach(stayDate => {
                    const rate = procData.rate[stayDate];
                    const compAverageRate = procData.compAverageRate[stayDate];
                    if (
                        rate
                        && typeof rate === 'object'
                        && 'value' in rate
                        && $.isNumeric(rate.value)
                        && compAverageRate
                        && typeof compAverageRate === 'object'
                        && 'value' in compAverageRate
                        && $.isNumeric(compAverageRate.value)
                    ) {
                        const diff = parseFloat(rate.value) - parseFloat(compAverageRate.value);
                        values.push(diff);
                    }
                });
        }
        if (values.length <= 1) {
            // No point coloring.
            min = null;
            max = null;
        } else {
            min = Math.min(...values);
            max = Math.max(...values);
        }

        return this.makeGradientColorCoderForMinAndMax(min, max);
    }

    renderPaceTag(pnn, pacePickup) {
        const positive = window.infinito.vao.controller.analyseAttributesHelper.positive;
        const negative = window.infinito.vao.controller.analyseAttributesHelper.negative;
        const neutral = window.infinito.vao.controller.analyseAttributesHelper.neutral;
        const bgColor = (pacePickup > 0) ? '#def7df' : (pacePickup < 0) ? '#ffe6e7' : null;
        let icon;
        let
            iconColor;

        if (pnn === positive) {
            icon = html`
                <vao-icon cls="${Constants.ICONS.EMOTE_SMILE}"></vao-icon>`;
            iconColor = 'text-success';
        } else if (pnn === negative) {
            icon = html`
                <vao-icon cls="${Constants.ICONS.EMOTE_FROWN}"></vao-icon>`;
            iconColor = 'text-danger';
        } else if (pnn === neutral) {
            icon = html`
                <vao-icon cls="${Constants.ICONS.EMOTE_MEH}"></vao-icon>`;
            iconColor = 'text-info';
        } else {
            return Constants.MISSING_STR;
        }

        return html`
            <vao-tag .label="${icon}" labelColor="${iconColor}" bgColor="${bgColor}"></vao-tag>`;
    }

    formatPercentageValue(unformattedValue) {
        var percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(1) + '%';
        }
        return unformattedValue;
    }

    fn_getcomppricing(stayDate, rowCells, positiontext, CompsetAvgValue, myratevalue) {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(stayDate)
        ) {
            rowCells.push(this.acquireTableCell(stayDate, {
                field: cellFieldMap.comppricing,
                value: html`-`
            }));
            return false;
        }

        let modelBiStats = window.infinito.vao.model.biStatistics;
        modelBiStats.fetchStatistics(
            this.hotel.id,
            modelBiStats.buildQuery({
                recordDate: this.recordDate,
                firstStayDate: stayDate,
                lastStayDate: stayDate,
                fields: [
                    modelBiStats.fields.compSetAvgLeadSiblingPace,
                    modelBiStats.fields.rateLeadSiblingPace,
                    modelBiStats.fields.ratesLastChanges,
                    modelBiStats.fields.occupancyLeaderCloserTrendProphecy,
                    modelBiStats.fields.rateLeaderCloserTrendProphecy,
                    modelBiStats.fields.rateLeaderFutureLeaderTrendProphecy,
                    modelBiStats.fields.compSetAvgLeaderCloserTrendProphecy,
                    modelBiStats.fields.compSetAvgLeaderFutureLeaderTrendProphecy
                ]
            }),
            (data, procData) => {
                let compSetAvgLeadSiblingPace = ((procData.compSetAvgLeadSiblingPace || {})[stayDate] || {}).value || null;
                const analyseAttributesHelper = window.infinito.vao.controller.analyseAttributesHelper;
                let subtitle = '';
                let badgeClass = '';
                if (
                    compSetAvgLeadSiblingPace
                    && typeof compSetAvgLeadSiblingPace === 'object'
                    && 'T0' in compSetAvgLeadSiblingPace
                    && 'setDenseRank' in compSetAvgLeadSiblingPace.T0
                    && 'setDenseLength' in compSetAvgLeadSiblingPace.T0
                    && $.isNumeric(compSetAvgLeadSiblingPace.T0.setDenseRank)
                ) {
                    const setRank = parseInt(compSetAvgLeadSiblingPace.T0.setDenseRank);
                    const scaledMap = analyseAttributesHelper.scaleRankMapForUniqueLength(
                        mapCompPacePerSetRank,
                        compSetAvgLeadSiblingPace.T0.setDenseLength
                    );
                    let mapVal = scaledMap[setRank] || null;
                    if (mapVal) {
                        subtitle = mapVal;
                        const pnn = analyseAttributesHelper.getCompPricePNNForSetRank(
                            setRank,
                            compSetAvgLeadSiblingPace.T0.setDenseLength
                        );
                        if (pnn === analyseAttributesHelper.positive) {
                            badgeClass = Constants.BADGES.CLASSES.SUCCESS;
                        } else if (pnn === analyseAttributesHelper.neutral) {
                            badgeClass = Constants.BADGES.CLASSES.INFO;
                        } else if (pnn === analyseAttributesHelper.negative) {
                            badgeClass = Constants.BADGES.CLASSES.DANGER;
                        } else {
                            badgeClass = Constants.BADGES.CLASSES.PRIMARY;
                        }
                    } else {
                        subtitle = Constants.MISSING_STR;
                    }
                } else {
                    subtitle = Constants.MISSING_STR;
                }
                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.comppricing,
                    value: subtitle === '-' ? '---' : html`
                        <vao-story-subtitle subtitle="${subtitle}" badgeClass="${badgeClass}"></vao-story-subtitle>`
                }));
                this.fn_getmypricing(stayDate, rowCells, subtitle, positiontext, CompsetAvgValue, myratevalue, procData);
            }
        );
    }

    fn_getmypricing(stayDate, rowCells, comppricesubtitle, positiontext, CompsetAvgValue, myratevalue, procData) {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(stayDate)
        ) {
            rowCells.push(this.acquireTableCell(stayDate, {
                field: cellFieldMap.mypricing,
                value: html`-`
            }));
            return false;
        }

        let rateLeadSiblingPace = ((procData.rateLeadSiblingPace || {})[stayDate] || {}).value || null;
        let subtitle = '';
        let badgeClass = '';
        if (
            rateLeadSiblingPace
            && typeof rateLeadSiblingPace === 'object'
            && 'T0' in rateLeadSiblingPace
            && 'setDenseRank' in rateLeadSiblingPace.T0
            && 'setDenseLength' in rateLeadSiblingPace.T0
            && $.isNumeric(rateLeadSiblingPace.T0.setDenseRank)
        ) {
            let setRank = parseInt(rateLeadSiblingPace.T0.setDenseRank);
            let setLength = rateLeadSiblingPace.T0.setDenseLength;
            let mapVal = window.infinito.vao.controller.analyseAttributesHelper
                .scaleRankMapForUniqueLength(
                    mapRatePacePerSetRank,
                    setLength
                )[setRank] || null;
            let pnn = window.infinito.vao.controller.analyseAttributesHelper.getMyPricePNNForSetRank(
                setRank,
                setLength
            );
            if (mapVal) {
                subtitle = mapVal;
                if (pnn === window.infinito.vao.controller.analyseAttributesHelper.positive) {
                    badgeClass = Constants.BADGES.CLASSES.SUCCESS;
                } else if (pnn === window.infinito.vao.controller.analyseAttributesHelper.negative) {
                    badgeClass = Constants.BADGES.CLASSES.DANGER;
                } else {
                    badgeClass = Constants.BADGES.CLASSES.PRIMARY;
                }
            } else {
                subtitle = Constants.MISSING_STR;
            }
        } else {
            subtitle = Constants.MISSING_STR;
        }
        rowCells.push(this.acquireTableCell(stayDate, {
            field: cellFieldMap.mypricing,
            value: subtitle === '-'
                ? html`
                        <p style="margin: auto;background: black;color: white;width: fit-content;padding: 0px 5px;border-radius: 5px;font-size: 10.2px;font-weight: 400;">
                            Not Selling</p>`
                : html`
                        <vao-story-subtitle subtitle="${subtitle}" badgeClass="${badgeClass}"></vao-story-subtitle>`
        }));
        subtitle = subtitle === '-' ? 'Not Selling' : subtitle;
        this.fn_getlastratechange(stayDate, rowCells, comppricesubtitle, subtitle, positiontext, CompsetAvgValue, myratevalue, procData);
    }

    fn_getlastratechange(stayDate, rowCells, comppricesubtitle, mypricesubtitle, positiontext, CompsetAvgValue, myratevalue, procData) {
        if (
            typeof this.hotel !== 'object'
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(stayDate)
        ) {
            rowCells.push(this.acquireTableCell(stayDate, {
                field: cellFieldMap.lastratechange,
                value: html`-`
            }));
            return false;
        }

        let ratesLastChanges = ((procData.ratesLastChanges || {})[stayDate] || {}).value || null;
        let primChannelName = window.infinito.vao.controller.channelHelper.getPrimaryChannelsName();


        let subtitle = '';
        let badgeClass = 'badge-primary';
        if (
            ratesLastChanges
            && typeof ratesLastChanges === 'object'
            && primChannelName in ratesLastChanges
            && typeof ratesLastChanges[primChannelName] === 'object'
            && 'dayDiff' in ratesLastChanges[primChannelName]
            && $.isNumeric(ratesLastChanges[primChannelName].dayDiff)
        ) {
            let dayDiffTxt = ratesLastChanges[primChannelName].dayDiff + ' days ago';
            if (
                $.isNumeric(ratesLastChanges[primChannelName].currentVal)
                && $.isNumeric(ratesLastChanges[primChannelName].offsetVal)
            ) {
                let diff = ratesLastChanges[primChannelName].currentVal
                    - ratesLastChanges[primChannelName].offsetVal;
                if (diff < 0) {
                    dayDiffTxt += ' decrease';
                } else if (diff > 0) {
                    dayDiffTxt += ' increase';
                }
            }
            subtitle = dayDiffTxt;
        } else {
            subtitle = Constants.MISSING_STR;
        }
        rowCells.push(this.acquireTableCell(stayDate, {
            field: cellFieldMap.lastratechange,
            value: subtitle === '-'
                ? html`
                        <div style="color: #fff;background-color: #dc3545;width: 87px;margin: 0px auto;border-radius: 4px;font-size: 10.2px;font-weight: 400;">
                            Over 30 days ago
                        </div>`
                : html`
                        <vao-story-subtitle subtitle="${subtitle}" badgeClass="${badgeClass}"></vao-story-subtitle>`
        }));
        this.fn_getforecasttext(stayDate, rowCells, comppricesubtitle, mypricesubtitle, subtitle, positiontext, CompsetAvgValue, myratevalue, procData);
    }

    fn_getforecasttext(stayDate, rowCells, comppricesubtitle, mypricesubtitle, lastratechangesubtitle, positiontext, CompsetAvgValue, myratevalue, procData) {
        let forecast = '';
        const _procData = procData || {};
        let occProphecy = ((_procData.occupancyLeaderCloserTrendProphecy || {})[stayDate] || {}).value || null;

        if (
            typeof occProphecy === 'object'
            && 'leadersClosersProphecy' in occProphecy
            && $.isNumeric(occProphecy.leadersClosersProphecy)
        ) {
            forecast = (window.infinito.vao.controller.utils.interpretOccupancyForecastOutput(
                occProphecy.leadersClosersProphecy
            ) || Constants.MISSING_STR);
        } else {
            forecast = Constants.MISSING_STR;
        }

        this.fn_getmypriceandcompriceleaderclosureandleadersfuturetrendprophecy(stayDate, rowCells, comppricesubtitle, mypricesubtitle, lastratechangesubtitle, forecast, positiontext, CompsetAvgValue, myratevalue, procData);
    }

    fn_getmypriceandcompriceleaderclosureandleadersfuturetrendprophecy(stayDate, rowCells, comppricesubtitle, mypricesubtitle, lastratechangesubtitle, forecast, positiontext, CompsetAvgValue, myratevalue, procData) {
        let rateLeaderCloserTrendProphecy = ((procData.rateLeaderCloserTrendProphecy || {})[stayDate] || {}).value || {};
        let rateLeaderFutureLeaderTrendProphecy = ((procData.rateLeaderFutureLeaderTrendProphecy || {})[stayDate] || {}).value || {};

        // Put these here to help batch lit async DOM update
        this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(stayDate);
        this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            stayDate,
            this.recordDate
        );

        if (
            rateLeaderCloserTrendProphecy
            && typeof rateLeaderCloserTrendProphecy === 'object'
            && 'leaders' in rateLeaderCloserTrendProphecy
            && Array.isArray(rateLeaderCloserTrendProphecy.leaders)
            && 'closers' in rateLeaderCloserTrendProphecy
            && Array.isArray(rateLeaderCloserTrendProphecy.closers)
        ) {
            this.numTimeLeadCloseLength = rateLeaderCloserTrendProphecy.leaders.length;

            let findMatchingCloser = (stayDate) => {
                return rateLeaderCloserTrendProphecy.closers.find((closer) => {
                    if (closer.stayDate === stayDate) {
                        return true;
                    }
                    return false;
                });
            };

            let numUp = 0;
            let ups = [];
            let numDown = 0;
            let downs = [];
            const isZero = (nowOrThen) => {
                const parsedNowOrThen = parseFloat(nowOrThen);
                return parsedNowOrThen === 0;
            };
            const isSold = (nowOrThen) => {
                return isZero(nowOrThen);
            };
            const canIncludeNowOrThenInAverage = (nowOrThen) => {
                if (!$.isNumeric(nowOrThen)) {
                    return false;
                }
                return !isZero(nowOrThen);
            };
            rateLeaderCloserTrendProphecy.leaders.forEach((leader) => {
                let closer = findMatchingCloser(leader.stayDate);
                if (!closer) {
                    return;
                }
                let prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                    leader.stayDate
                );
                let then = leader.value;
                let now = closer.value;
                if (!$.isNumeric(then)) {
                    then = Constants.MISSING_STR;
                }
                if (!$.isNumeric(now)) {
                    now = Constants.MISSING_STR;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                    // Special case told to handle when going from selling to closed is actually an increase.
                    if (isSold(now)) {
                        numUp++;
                    } else {
                        numDown++;
                    }
                    if (
                        canIncludeNowOrThenInAverage(now)
                        && canIncludeNowOrThenInAverage(then)
                    ) {
                        downs.push(now - then);
                    }
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                    // Special case told to handle when property was sold out but now selling again decrease.
                    if (isSold(then)) {
                        numDown++;
                    } else {
                        numUp++;
                    }
                    if (
                        canIncludeNowOrThenInAverage(now)
                        && canIncludeNowOrThenInAverage(then)
                    ) {
                        ups.push(now - then);
                    }
                }
                then = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    then,
                    Constants.RATE_DIGITS,
                    true,
                    this.locale
                );
                now = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    now,
                    Constants.RATE_DIGITS,
                    true,
                    this.locale
                );
                this.leaderCloserListItems.push({
                    stayDate: prettyStayDate,
                    then: then,
                    now: now
                });
            });

            if (this.numTimeLeadCloseLength > 0) {
                if (numDown === numUp) {
                    this.dropIncreasePercentAnalysis = '0% of the time we changed our rate ';
                    this.dropIncreaseAverageAnalysis = Constants.MISSING_STR;
                } else if (numDown > numUp) {
                    let percent = Math.floor((numDown / this.numTimeLeadCloseLength) * 100);
                    this.dropIncreasePercentAnalysis = percent + '% of the time we dropped our rate ';
                    let sum = downs.reduce((a, b) => a + b, 0);
                    let avg = (sum / downs.length) || 0;
                    this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper
                        .formatMoneyBracketStyle(
                            avg,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                } else {
                    let percent = Math.floor((numUp / this.numTimeLeadCloseLength) * 100);
                    this.dropIncreasePercentAnalysis = percent + '% of the time we increased our rate ';
                    let sum = ups.reduce((a, b) => a + b, 0);
                    let avg = (sum / ups.length) || 0;
                    this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper
                        .formatMoneyBracketStyle(
                            avg,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                }
            }
        } else {
            this.numTimeLeadCloseLength = Constants.MISSING_STR;
        }

        if (
            this.canShowWhatToExpected7Days()
            && rateLeaderFutureLeaderTrendProphecy
            && typeof rateLeaderFutureLeaderTrendProphecy === 'object'
            && 'leaders' in rateLeaderFutureLeaderTrendProphecy
            && Array.isArray(rateLeaderFutureLeaderTrendProphecy.leaders)
            && 'futureLeaders' in rateLeaderFutureLeaderTrendProphecy
            && Array.isArray(rateLeaderFutureLeaderTrendProphecy.futureLeaders)
        ) {
            let findMatchingFutureLeader = (stayDate) => {
                return rateLeaderFutureLeaderTrendProphecy.futureLeaders.find((closer) => {
                    if (closer.stayDate === stayDate) {
                        return true;
                    }
                    return false;
                });
            };

            let numUp = 0;
            let numDown = 0;
            rateLeaderFutureLeaderTrendProphecy.leaders.forEach((leader) => {
                let futureLeader = findMatchingFutureLeader(leader.stayDate);
                if (!futureLeader) {
                    return;
                }
                let then = leader.value;
                let now = futureLeader.value;
                if (!$.isNumeric(then)) {
                    then = Constants.MISSING_STR;
                }
                if (!$.isNumeric(now)) {
                    now = Constants.MISSING_STR;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                    numDown++;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                    numUp++;
                }
            });

            if (numDown === numUp) {
                this.dropIncreasePercent7Days = '50/50';
                this.dropIncreasePercent7DaysTxt = 'change';
            } else if (numDown > numUp) {
                let percent = Math.floor(
                    (numDown / rateLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                );
                this.dropIncreasePercent7Days = percent + '%';
                this.dropIncreasePercent7DaysTxt = 'drop';
            } else {
                let percent = Math.floor(
                    (numUp / rateLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                );
                this.dropIncreasePercent7Days = percent + '%';
                this.dropIncreasePercent7DaysTxt = 'increase';
            }
        } else {
            this.dropIncreasePercent7Days = Constants.MISSING_STR;
            this.dropIncreasePercent7DaysTxt = Constants.MISSING_STR;
        }

        var HistoricDataInsightForYourPrice = this.dropIncreasePercentAnalysis + ' within ' + this.leadTime + ' days out.';
        var howmanydaysourmyprice = this.leadTime;

        /** ****COMP PRICE DATA****** */
        let compSetAvgLeaderCloserTrendProphecy = ((procData.compSetAvgLeaderCloserTrendProphecy || {})[stayDate] || {}).value || {};
        let compSetAvgLeaderFutureLeaderTrendProphecy = ((procData.compSetAvgLeaderFutureLeaderTrendProphecy || {})[stayDate] || {}).value || {};

        // Put these here to help batch lit async DOM update
        this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(stayDate);
        this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(
            stayDate,
            this.recordDate
        );

        if (
            compSetAvgLeaderCloserTrendProphecy
            && typeof compSetAvgLeaderCloserTrendProphecy === 'object'
            && 'leaders' in compSetAvgLeaderCloserTrendProphecy
            && Array.isArray(compSetAvgLeaderCloserTrendProphecy.leaders)
            && 'closers' in compSetAvgLeaderCloserTrendProphecy
            && Array.isArray(compSetAvgLeaderCloserTrendProphecy.closers)
        ) {
            this.numTimeLeadCloseLength = compSetAvgLeaderCloserTrendProphecy.leaders.length;

            let findMatchingCloser = (stayDate) => {
                return compSetAvgLeaderCloserTrendProphecy.closers.find((closer) => {
                    if (closer.stayDate === stayDate) {
                        return true;
                    }
                    return false;
                });
            };
            let numUp = 0;
            let ups = [];
            let numDown = 0;
            let downs = [];
            const isZero = (nowOrThen) => {
                const parsedNowOrThen = parseFloat(nowOrThen);
                return parsedNowOrThen === 0;
            };
            const isSold = (nowOrThen) => {
                return isZero(nowOrThen);
            };
            const canIncludeNowOrThenInAverage = (nowOrThen) => {
                if (!$.isNumeric(nowOrThen)) {
                    return false;
                }
                const parsedNowOrThen = parseFloat(nowOrThen);
                return parsedNowOrThen !== 0;
            };
            compSetAvgLeaderCloserTrendProphecy.leaders.forEach((leader) => {
                let closer = findMatchingCloser(leader.stayDate);
                if (!closer) {
                    return;
                }
                let prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(
                    leader.stayDate
                );
                let then = leader.value;
                let now = closer.value;
                if (!$.isNumeric(then)) {
                    then = Constants.MISSING_STR;
                }
                if (!$.isNumeric(now)) {
                    now = Constants.MISSING_STR;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                    // Special case told to handle when going from selling to closed is actually an increase.
                    if (isSold(now)) {
                        numUp++;
                    } else {
                        numDown++;
                    }
                    if (
                        canIncludeNowOrThenInAverage(now)
                        && canIncludeNowOrThenInAverage(then)
                    ) {
                        downs.push(now - then);
                    }
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                    // Special case told to handle when property was sold out but now selling again decrease.
                    if (isSold(then)) {
                        numDown++;
                    } else {
                        numUp++;
                    }
                    if (
                        canIncludeNowOrThenInAverage(now)
                        && canIncludeNowOrThenInAverage(then)
                    ) {
                        ups.push(now - then);
                    }
                }
                then = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    then,
                    Constants.RATE_DIGITS,
                    true,
                    this.locale
                );
                now = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                    now,
                    Constants.RATE_DIGITS,
                    true,
                    this.locale
                );
                this.leaderCloserListItems.push({
                    stayDate: prettyStayDate,
                    then: then,
                    now: now
                });
            });

            if (this.numTimeLeadCloseLength > 0) {
                if (numDown === numUp) {
                    this.dropIncreasePercentAnalysis = '0% of the time our comp set changed their rate ';
                    this.dropIncreaseAverageAnalysis = Constants.MISSING_STR;
                } else if (numDown > numUp) {
                    let percent = Math.floor((numDown / this.numTimeLeadCloseLength) * 100);
                    this.dropIncreasePercentAnalysis = percent
                        + '% of the time our comp set dropped their rate ';
                    let sum = downs.reduce((a, b) => a + b, 0);
                    let avg = (sum / downs.length) || 0;
                    this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper
                        .formatMoneyBracketStyle(
                            avg,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                } else {
                    let percent = Math.floor((numUp / this.numTimeLeadCloseLength) * 100);
                    this.dropIncreasePercentAnalysis = percent
                        + '% of the time our comp set increased their rate ';
                    let sum = ups.reduce((a, b) => a + b, 0);
                    let avg = (sum / ups.length) || 0;
                    this.dropIncreaseAverageAnalysis = window.infinito.vao.controller.moneyHelper
                        .formatMoneyBracketStyle(
                            avg,
                            Constants.RATE_DIGITS,
                            true,
                            this.locale
                        );
                }
            }
        } else {
            this.numTimeLeadCloseLength = Constants.MISSING_STR;
        }

        if (
            this.canShowWhatToExpected7Days()
            && compSetAvgLeaderFutureLeaderTrendProphecy
            && typeof compSetAvgLeaderFutureLeaderTrendProphecy === 'object'
            && 'leaders' in compSetAvgLeaderFutureLeaderTrendProphecy
            && Array.isArray(compSetAvgLeaderFutureLeaderTrendProphecy.leaders)
            && 'futureLeaders' in compSetAvgLeaderFutureLeaderTrendProphecy
            && Array.isArray(compSetAvgLeaderFutureLeaderTrendProphecy.futureLeaders)
        ) {
            let findMatchingFutureLeader = (stayDate) => {
                return compSetAvgLeaderFutureLeaderTrendProphecy.futureLeaders.find((closer) => {
                    if (closer.stayDate === stayDate) {
                        return true;
                    }
                    return false;
                });
            };

            let numUp = 0;
            let numDown = 0;
            compSetAvgLeaderFutureLeaderTrendProphecy.leaders.forEach((leader) => {
                let futureLeader = findMatchingFutureLeader(leader.stayDate);
                if (!futureLeader) {
                    return;
                }
                let then = leader.value;
                let now = futureLeader.value;
                if (!$.isNumeric(then)) {
                    then = Constants.MISSING_STR;
                }
                if (!$.isNumeric(now)) {
                    now = Constants.MISSING_STR;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now < then) {
                    numDown++;
                }
                if ($.isNumeric(then) && $.isNumeric(now) && now > then) {
                    numUp++;
                }
            });

            if (numDown === numUp) {
                this.dropIncreasePercent7Days = '50/50';
                this.dropIncreasePercent7DaysTxt = 'change';
            } else if (numDown > numUp) {
                let percent = Math.floor(
                    (numDown / compSetAvgLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                );
                this.dropIncreasePercent7Days = percent + '%';
                this.dropIncreasePercent7DaysTxt = 'drop';
            } else {
                let percent = Math.floor(
                    (numUp / compSetAvgLeaderFutureLeaderTrendProphecy.leaders.length) * 100
                );
                this.dropIncreasePercent7Days = percent + '%';
                this.dropIncreasePercent7DaysTxt = 'increase';
            }
        } else {
            this.dropIncreasePercent7Days = Constants.MISSING_STR;
            this.dropIncreasePercent7DaysTxt = Constants.MISSING_STR;
        }

        var HistoricDataInsightForCompPrice = this.dropIncreasePercentAnalysis + ' within ' + this.leadTime + ' days out.';

        this.fn_getrateguidance(stayDate, rowCells, comppricesubtitle, mypricesubtitle, lastratechangesubtitle, forecast, positiontext, CompsetAvgValue, myratevalue, HistoricDataInsightForYourPrice, HistoricDataInsightForCompPrice, howmanydaysourmyprice);
    }

    fn_getrateguidance(stayDate, rowCells, comppricesubtitle, mypricesubtitle, lastratechangesubtitle, forecast, positiontext, CompsetAvgValue, myratevalue, HistoricDataInsightForYourPrice, HistoricDataInsightForCompPrice, howmanydaysourmyprice) {
        if (mypricesubtitle === 'Not Selling') {
            rowCells.push(this.acquireTableCell(stayDate, {
                field: cellFieldMap.rateguidance,
                value: html`<p
                        style="margin: auto;background: black;color: white;width: fit-content;padding: 0px 5px;border-radius: 5px;font-size: 10.2px;font-weight: 400;">
                    Not Selling</p>`
            }));
            this.fetchNotes(stayDate, this.hotel.id, rowCells, '', '');
        } else {
            let positioningtext = rowCells[1].__value.values[0];

            const prompt = `Given the following, please provide a clear price banding recommendation for "my" pricing strategy without deviation from instructions. First step: increase, decrease, maintain. Then add rate banding (min/ max increase or decrease), give final result back. No explanation required.:
                            My Forecast Demand: ${forecast}.
                            My Current Pricing Position: My current price positioning against competitors is ${positioningtext}
                            Competitor's Average Pricing: ${CompsetAvgValue} This is within their ${positioningtext}.
                            My Current Price: ${myratevalue}, which is ${positioningtext}
                            My historic Price Adjustments: Historically, ${HistoricDataInsightForYourPrice}
                            Competitors historic Price Adjustments: ${HistoricDataInsightForCompPrice}
                            Required Output: Based on the above data, answer the question: should we Increase, Decrease or Maintain our current pricing? If an increase / decrease is recommended, specify the rate banding by how much we should increase / decrease using min. Use the following wording withoutThe wording for increase decrease has to be: like the example:example: Increase between x (min) and y (max) or decrease between x (min) and y (max).If Maintain then just recommend maintain. No explanation. stick to required output.`

            let requestDataObj = {
                'prompt': prompt,
                'op': 'sendPrompt',
                'serviceName': "chatgptapi.php"
            };
            var flag = this;
            sendRequest(requestDataObj, function (res) {
                var jsonObj = JSON.parse(res);
                console.log(jsonObj);

                var ab = jsonObj['data'];
                ab = ab.trim();
                flag.openAiRateGuidance = ab;
                var notesString = 'Forecast:' + forecast + ', Positioning: ' + positioningtext + ', Current Price: ' + myratevalue + ', Price band: ' + ab;

                let formatting = '';
                if (ab.includes('Increase')) {
                    formatting = html`
                            <div style="color: #fff;background-color: #28a745;width: fit-content;margin: 0px auto;border-radius: 4px;font-size: 10.2px;font-weight: 400;padding-left: 10px;padding-right: 10px;">
                                ${ab.trim()}
                            </div>`;
                } else if (ab.includes('Decrease')) {
                    formatting = html`
                            <div style="color: #fff;background-color: #dc3545;width: fit-content;margin: 0px auto;border-radius: 4px;font-size: 10.2px;font-weight: 400;padding-left: 10px;padding-right: 10px;">
                                ${ab.trim()}
                            </div>`;
                } else if (ab.includes('Maintain')) {
                    formatting = html`
                            <div style="color: #fff;background-color: #2196f3;width: fit-content;px;margin: 0px auto;border-radius: 4px;font-size: 10.2px;font-weight: 400;padding-left: 10px;padding-right: 10px;">
                                ${ab.trim()}
                            </div>`;
                }
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.rateguidance,
                    value: formatting
                }));

                flag.fetchNotes(stayDate, flag.hotel.id, rowCells, ab, notesString);
            });
        }
    }

    onClickAgreerateDecision(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e) {
        let dataObj = {
            hotel_id: hotel_id,
            RateGuidance: JSON.stringify(RateGuidance),
            staydate: stayDate,
            notesString: JSON.stringify(notesString),
            rateDecision: 'Agree',
            op: 'saveRateDecision',
            serviceName: 'desktop.php'
        };
        var flag = flg;
        rowCells.push(flg.acquireTableCell(stayDate, {
            field: cellFieldMap.ratedecision,
            value: html`-`
        }));
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            rowCells.push(flag.acquireTableCell(stayDate, {
                field: cellFieldMap.ratedecision,
                value: html`
                    <div>
                        <vao-button
                                startIcon="${Constants.ICONS.TICKCRICLE}"
                                tooltip="Agree"
                                variant="invert"
                                color="success"
                                size="small"
                                @click=${flag.onClickAgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.TIMESCIRCLE}"
                                tooltip="Disagree"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickDisgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.YINYANG}"
                                tooltip="Strategy"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickStrategyrateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                    </div>`
            }));
        });
    }

    onClickDisgreerateDecision(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e) {
        let dataObj = {
            hotel_id: hotel_id,
            RateGuidance: JSON.stringify(RateGuidance),
            staydate: stayDate,
            notesString: JSON.stringify(notesString),
            rateDecision: 'Disagree',
            op: 'saveRateDecision',
            serviceName: 'desktop.php'
        };
        var flag = flg;
        rowCells.push(flg.acquireTableCell(stayDate, {
            field: cellFieldMap.ratedecision,
            value: html`-`
        }));
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            rowCells.push(flag.acquireTableCell(stayDate, {
                field: cellFieldMap.ratedecision,
                value: html`
                    <div>
                        <vao-button
                                startIcon="${Constants.ICONS.TICKCRICLE}"
                                tooltip="Agree"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickAgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.TIMESCIRCLE}"
                                tooltip="Disagree"
                                variant="invert"
                                color="secondary"
                                size="small"
                                @click=${flag.onClickDisgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.YINYANG}"
                                tooltip="Strategy"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickStrategyrateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                    </div>`
            }));
        });
    }

    onClickStrategyrateDecision(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e) {
        let dataObj = {
            hotel_id: hotel_id,
            RateGuidance: JSON.stringify(RateGuidance),
            staydate: stayDate,
            notesString: JSON.stringify(notesString),
            rateDecision: 'Strategy',
            op: 'saveRateDecision',
            serviceName: 'desktop.php'
        };
        var flag = flg;
        rowCells.push(flg.acquireTableCell(stayDate, {
            field: cellFieldMap.ratedecision,
            value: html`-`
        }));

        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            rowCells.push(flag.acquireTableCell(stayDate, {
                field: cellFieldMap.ratedecision,
                value: html`
                    <div>
                        <vao-button
                                startIcon="${Constants.ICONS.TICKCRICLE}"
                                tooltip="Agree"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickAgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.TIMESCIRCLE}"
                                tooltip="Disagree"
                                variant="invert"
                                color="light"
                                size="small"
                                @click=${flag.onClickDisgreerateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                        <vao-button
                                startIcon="${Constants.ICONS.YINYANG}"
                                tooltip="Strategy"
                                variant="invert"
                                color="primary"
                                size="small"
                                @click=${flag.onClickStrategyrateDecision.bind(hotel_id, stayDate, RateGuidance, rowCells, flg, notesString, e)}
                        ></vao-button>
                    </div>`
            }));
        });
    }

    canShowWhatToExpected7Days() {
        return Number.isInteger(this.leadTime) && this.leadTime > 7;
    }

    fill() {
        if (
            !this.hotel
            || !this.hotel.id
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.recordDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.startDate)
            || !window.infinito.vao.controller.dateHelper.isDateStr(this.endDate)
            || !$.isNumeric(this.pickupSetting)
        ) {
            this.rows = [];
            return;
        }


        const biStatistics = window.infinito.vao.model.biStatistics;
        const dates = window.infinito.vao.controller.dateHelper.calcStayDateRangeArray(this.startDate, this.endDate);
        this.isLoading = true;
        this.rows = [];

        requestCount++;
        const preRequestCount = requestCount;

        (new Promise((resolve) => {
            const vaoSettings = window.infinito.vao.model.vaoSettings;
            const housekeeperSettingKey = vaoSettings.obtainHotelIdHousekeeperPerShiftKey(
                this.hotel.id
            );
            vaoSettings.fetchSettingByKey(
                window.infinito.vao.controller.storageHelper.getIdentitiesId(),
                housekeeperSettingKey,
                (setting) => {
                    if (setting && $.isNumeric(setting.value)) {
                        this.roomsPerHousekeeper = parseInt(setting.value);
                    }
                    resolve();
                }
            );
        })).then(() => {
            biStatistics.fetchStatistics(
                this.hotel.id,
                biStatistics.buildQuery({
                    recordDate: this.recordDate,
                    firstStayDate: this.startDate,
                    lastStayDate: this.endDate,
                    fields: [
                        biStatistics.fields.roomsToSell,
                        biStatistics.fields.roomsOtb,
                        biStatistics.fields.revenue,
                        biStatistics.fields.adr,
                        biStatistics.fields.roomsOtbLastYear,
                        biStatistics.fields.revenueLastYear,
                        biStatistics.fields.adrLastYear,
                        biStatistics.fields.roomsOtbPickup,
                        biStatistics.fields.revenuePickup,
                        biStatistics.fields.pickupsAdr,
                        biStatistics.fields.adrPickup,
                        biStatistics.fields.rate,
                        biStatistics.fields.ratePickup,
                        biStatistics.fields.compAverageRate,
                        biStatistics.fields.compPrices,
                        biStatistics.fields.compPricesPickup,
                        biStatistics.fields.roomsOtbLeadSiblingPacePickup,
                        biStatistics.fields.ratesPickup,
                        biStatistics.fields.occupancy
                    ],
                    pickupOffset: this.pickupSetting
                }),
                (data, procData) => {
                    if (requestCount !== preRequestCount) {
                        return; // Another ajax has taken over.
                    }

                    const _procData = (procData || {});
                    if (!_procData || Object.keys(_procData).length === 0) {
                        this.isLoading = false;
                        return;
                    }

                    const adrMoveColorCoder = this.makeAdrMoveGradientColorCoder(_procData);
                    const compSetAvgColorCoder = this.makeCompSetAvgGradientColorCoder(_procData);

                    let rows = [];
                    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                    dates.forEach(stayDate => {
                        const birthday = new Date(stayDate);
                        const day1 = birthday.getDay();

                        let rowCells = [];
                        const prettyStayDate = window.infinito.vao.controller.dateHelper
                            .getShortDayDateMonFullYearString(stayDate);

                        const filteredByWeekDay = this.dayofweekfilter === days[day1];
                        const showAllDays = this.dayofweekfilter === 'all_days';
                        if (filteredByWeekDay || showAllDays) {
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.stayDate,
                                value: html`
                                    <vao-button
                                            text="${prettyStayDate}"
                                            variant="invert"
                                            size="xs"
                                            @click=${this.onClickStayDate.bind(this, stayDate)}
                                    ></vao-button>
                                `
                            }));

                            let positiontext = this.fetchPosition(stayDate, this.recordDate, rowCells);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.positioning,
                                value: ''
                            }));


                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.notes,
                                value: ''
                            }));

                            if (
                                _procData.roomsToSell
                                && _procData.roomsToSell[stayDate]
                                && 'value' in _procData.roomsToSell[stayDate]
                                && $.isNumeric(_procData.roomsToSell[stayDate].value)
                            ) {
                                let value = parseInt(_procData.roomsToSell[stayDate].value, 10);
                                let labelColor = null;
                                if (value !== 0) {
                                    // value *= -1; // Because Fabian dont wants the inverse rendered.
                                    if (value < 0) {
                                        // There actually is rooms still left to sell.
                                        labelColor = Constants.COLORS.HEX.DANGER;
                                    }
                                }
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.roomsToSell,
                                    value: html`
                                        <vao-tag
                                                .label="${value}"
                                                .labelColor="${labelColor}"
                                                canInterpretBgColor="false"
                                        ></vao-tag>
                                    `
                                }));
                            }

                            if (
                                _procData.roomsOtb
                                && _procData.roomsOtb[stayDate]
                                && 'value' in _procData.roomsOtb[stayDate]
                                && $.isNumeric(_procData.roomsOtb[stayDate].value)
                            ) {
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.roomsOtb,
                                    value: _procData.roomsOtb[stayDate].value
                                }));
                            }

                            if (
                                _procData.occupancy
                                && _procData.occupancy[stayDate]
                                && 'value' in _procData.occupancy[stayDate]
                                && $.isNumeric(_procData.occupancy[stayDate].value)
                            ) {
                                const occupancyvalue = _procData.occupancy[stayDate].value;
                                var occbadgeClass = '';

                                if (occupancyvalue < 0.29) {
                                    occbadgeClass = 'color:#030289;';
                                } else if (occupancyvalue > 0.29 && occupancyvalue < 0.39) {
                                    occbadgeClass = 'color:#3e2f6b;';
                                } else if (occupancyvalue >= 0.39 && occupancyvalue < 0.49) {
                                    occbadgeClass = 'color:#67527c;';
                                } else if (occupancyvalue >= 0.49 && occupancyvalue < 0.59) {
                                    occbadgeClass = 'color:#916e40;';
                                } else if (occupancyvalue >= 0.59 && occupancyvalue < 0.69) {
                                    occbadgeClass = 'color:#c89724;';
                                } else if (occupancyvalue >= 0.69 && occupancyvalue < 0.79) {
                                    occbadgeClass = 'color:#f3b80d;';
                                } else if (occupancyvalue >= 0.79 && occupancyvalue < 0.89) {
                                    occbadgeClass = 'color:#c4ba17;';
                                } else if (occupancyvalue >= 0.89 && occupancyvalue < 0.95) {
                                    occbadgeClass = 'color:#99b524;';
                                } else if (occupancyvalue > 0.95 && occupancyvalue <= 1) {
                                    occbadgeClass = 'color:#4aab3b;';
                                } else {
                                    occbadgeClass = 'color:green;';
                                }

                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.occupancy,
                                    value: html`
                                        <div class="vao__components--storyForecastSubtitle" style="${occbadgeClass}">
                                            ${this.formatPercentageValue(_procData.occupancy[stayDate].value)}
                                        </div>`
                                }));
                            }

                            if (
                                _procData.revenue
                                && _procData.revenue[stayDate]
                                && 'value' in _procData.revenue[stayDate]
                                && $.isNumeric(_procData.revenue[stayDate].value)
                            ) {
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    _procData.revenue[stayDate].value,
                                    Constants.REVENUE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.revenue,
                                    value: formatted
                                }));
                            }

                            if (
                                _procData.adr
                                && _procData.adr[stayDate]
                                && 'value' in _procData.adr[stayDate]
                                && $.isNumeric(_procData.adr[stayDate].value)
                            ) {
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    _procData.adr[stayDate].value,
                                    Constants.ADR_DIGITS,
                                    true,
                                    this.hotel.locale,
                                    1
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.adr,
                                    value: formatted
                                }));
                            }

                            if (
                                _procData.roomsOtbPickup
                                && _procData.roomsOtbPickup[stayDate]
                                && 'value' in _procData.roomsOtbPickup[stayDate]
                                && $.isNumeric(_procData.roomsOtbPickup[stayDate].value)
                            ) {
                                let value = _procData.roomsOtbPickup[stayDate].value;
                                const color = window.infinito.vao.controller.pickupHelper.getPickupColorFor(value);
                                let bgColor = null;
                                if (color) {
                                    bgColor = ColorArray.hexToRGBA(color, 0.15);
                                }
                                const numVarBlock = html`
                                    <vao-numeric-variance-block
                                            numericValue="${value}"
                                            formattedValue="${value}"
                                    ></vao-numeric-variance-block>
                                `;
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.roomsOtbPickup,
                                    value: html`
                                        <vao-tag
                                                .label="${numVarBlock}"
                                                bgColor="${bgColor}"
                                        ></vao-tag>`
                                }));
                            }

                            if (
                                _procData.revenuePickup
                                && _procData.revenuePickup[stayDate]
                                && 'value' in _procData.revenuePickup[stayDate]
                                && $.isNumeric(_procData.revenuePickup[stayDate].value)
                            ) {
                                const value = parseFloat(_procData.revenuePickup[stayDate].value);
                                const formattedValue = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    value,
                                    Constants.REVENUE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.revenuePickup,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${value}"
                                                formattedValue="${formattedValue}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            if (
                                _procData.roomsOtb
                                && _procData.roomsOtbLastYear
                                && _procData.roomsOtb[stayDate]
                                && _procData.roomsOtbLastYear[stayDate]
                                && 'value' in _procData.roomsOtb[stayDate]
                                && 'value' in _procData.roomsOtbLastYear[stayDate]
                                && $.isNumeric(_procData.roomsOtb[stayDate].value)
                                && $.isNumeric(_procData.roomsOtbLastYear[stayDate].value)
                            ) {
                                const roomsOtb = parseInt(_procData.roomsOtb[stayDate].value, 10);
                                const roomsOtbLastYear = parseInt(_procData.roomsOtbLastYear[stayDate].value, 10);
                                const diff = String(roomsOtb - roomsOtbLastYear);
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.roomsOtbVsLastYear,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${diff}"
                                                formattedValue="${diff}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            if (
                                _procData.revenue
                                && _procData.revenueLastYear
                                && _procData.revenue[stayDate]
                                && _procData.revenueLastYear[stayDate]
                                && 'value' in _procData.revenue[stayDate]
                                && 'value' in _procData.revenueLastYear[stayDate]
                                && $.isNumeric(_procData.revenue[stayDate].value)
                                && $.isNumeric(_procData.revenueLastYear[stayDate].value)
                            ) {
                                const revenue = parseFloat(_procData.revenue[stayDate].value);
                                const revenueLastYear = parseFloat(_procData.revenueLastYear[stayDate].value);
                                const diff = revenue - revenueLastYear;
                                const formattedDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    diff,
                                    Constants.REVENUE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.revenueVsLastYear,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${diff}"
                                                formattedValue="${formattedDiff}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            if (
                                _procData.adr
                                && _procData.adrLastYear
                                && _procData.adr[stayDate]
                                && _procData.adrLastYear[stayDate]
                                && 'value' in _procData.adr[stayDate]
                                && 'value' in _procData.adrLastYear[stayDate]
                                && $.isNumeric(_procData.adr[stayDate].value)
                                && $.isNumeric(_procData.adrLastYear[stayDate].value)
                            ) {
                                const adr = parseFloat(_procData.adr[stayDate].value);
                                const adrLastYear = parseFloat(_procData.adrLastYear[stayDate].value);
                                const diff = adr - adrLastYear;
                                const formattedDiff = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    diff,
                                    Constants.ADR_DIGITS,
                                    true,
                                    this.hotel.locale,
                                    1
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.adrVsLastYear,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${diff}"
                                                formattedValue="${formattedDiff}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            if (
                                _procData.pickupsAdr
                                && _procData.pickupsAdr[stayDate]
                                && 'value' in _procData.pickupsAdr[stayDate]
                                && $.isNumeric(_procData.pickupsAdr[stayDate].value)
                            ) {
                                const value = _procData.pickupsAdr[stayDate].value;
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    value,
                                    Constants.ADR_DIGITS,
                                    true,
                                    this.hotel.locale,
                                    1
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.pickupsAdr,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${value}"
                                                formattedValue="${formatted}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            if (
                                _procData.adrPickup
                                && _procData.adrPickup[stayDate]
                                && 'value' in _procData.adrPickup[stayDate]
                                && $.isNumeric(_procData.adrPickup[stayDate].value)
                            ) {
                                const value = _procData.adrPickup[stayDate].value;
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    value,
                                    Constants.ADR_DIGITS,
                                    true,
                                    this.hotel.locale,
                                    1
                                );
                                const bgColor = adrMoveColorCoder(value);
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.adrPickup,
                                    value: html`
                                        <vao-tag
                                                label="${formatted}"
                                                bgColor="${bgColor}"
                                        ></vao-tag>
                                    `
                                }));
                            }

                            if (
                                _procData.roomsOtbLeadSiblingPacePickup
                                && _procData.roomsOtbLeadSiblingPacePickup[stayDate]
                                && 'value' in _procData.roomsOtbLeadSiblingPacePickup[stayDate]
                                && _procData.roomsOtbLeadSiblingPacePickup[stayDate].value
                                && typeof _procData.roomsOtbLeadSiblingPacePickup[stayDate].value === 'object'
                                && 'T0' in _procData.roomsOtbLeadSiblingPacePickup[stayDate].value
                            ) {
                                const value = _procData.roomsOtbLeadSiblingPacePickup[stayDate].value;
                                const variance = parseInt(value.T0.valueDense, 10);
                                const rankNow = value.T0.raw.setDenseRank;
                                const lengthNow = value.T0.raw.setDenseLength;
                                const pnn = window.infinito.vao.controller.analyseAttributesHelper
                                    .getMyOtbPNNForSetRank(rankNow, lengthNow);
                                const tagTemplateResult = this.renderPaceTag(pnn, variance);
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.roomsOtbPace,
                                    value: tagTemplateResult
                                }));
                            }

                            if (
                                _procData.ratesPickup
                                && _procData.ratesPickup[stayDate]
                                && 'value' in _procData.ratesPickup[stayDate]
                                && _procData.ratesPickup[stayDate].value
                                && typeof _procData.ratesPickup[stayDate].value === 'object'
                            ) {
                                const nowAndThen = window.infinito.vao.controller.analyseAttributesHelper
                                    .interpretRatesPickupForAvailability(_procData.ratesPickup[stayDate]);
                                const now = nowAndThen.now;
                                const then = nowAndThen.then;
                                let variance = 0;
                                if (now === 'positive') {
                                    if (then === 'neutral' || then === 'negative') {
                                        variance = 1;
                                    }
                                } else if (now === 'neutral') {
                                    if (then === 'positive') {
                                        variance = -1;
                                    } else if (then === 'negative') {
                                        variance = 1;
                                    }
                                } else if (now === 'negative') {
                                    if (then === 'positive' || then === 'neutral') {
                                        variance = -1;
                                    }
                                }
                                const tagTemplateResult = this.renderPaceTag(now, variance);
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.availabilityPace,
                                    value: tagTemplateResult
                                }));
                            }

                            let myratevalue = 0;
                            if (
                                _procData.rate
                                && _procData.rate[stayDate]
                                && 'value' in _procData.rate[stayDate]
                                /* && $.isNumeric(_procData.rate[stayDate].value) */
                            ) {
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    _procData.rate[stayDate].value,
                                    Constants.RATE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                myratevalue = formatted;
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.rate,
                                    value: formatted
                                }));
                            }

                            if (
                                _procData.ratePickup
                                && _procData.ratePickup[stayDate]
                                && 'value' in _procData.ratePickup[stayDate]
                                && $.isNumeric(_procData.ratePickup[stayDate].value)
                            ) {
                                const value = _procData.ratePickup[stayDate].value;
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    value,
                                    Constants.RATE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.ratePickup,
                                    value: html`
                                        <vao-numeric-variance-block
                                                numericValue="${value}"
                                                formattedValue="${formatted}"
                                        ></vao-numeric-variance-block>
                                    `
                                }));
                            }

                            let CompsetAvgValue = 0;
                            if (
                                _procData.compAverageRate
                                && _procData.compAverageRate[stayDate]
                                && 'value' in _procData.compAverageRate[stayDate]
                                && $.isNumeric(_procData.compAverageRate[stayDate].value)
                            ) {
                                const diff = parseFloat(_procData.compAverageRate[stayDate].value);
                                const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                    diff,
                                    Constants.RATE_DIGITS,
                                    true,
                                    this.hotel.locale
                                );
                                CompsetAvgValue = formatted;
                                const bgColor = compSetAvgColorCoder(diff);
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.compSetAvg,
                                    value: html`
                                        <vao-tag
                                                label="${formatted}"
                                                bgColor="${bgColor}"
                                        ></vao-tag>
                                    `
                                }));
                            }

                            let compPricesPickup = {};
                            if (
                                _procData.compPricesPickup
                                && _procData.compPricesPickup[stayDate]
                                && 'value' in _procData.compPricesPickup[stayDate]
                                && isJSONString(_procData.compPricesPickup[stayDate].value)
                            ) {
                                compPricesPickup = JSON.parse(_procData.compPricesPickup[stayDate].value);
                            }

                            if (
                                _procData.compPrices
                                && _procData.compPrices[stayDate]
                                && 'value' in _procData.compPrices[stayDate]
                                && _procData.compPrices[stayDate].value != 'no data'
                                && isJSONString(_procData.compPrices[stayDate].value)
                            ) {
                                const compPrices = JSON.parse(_procData.compPrices[stayDate].value);
                                Object.keys(compPrices)
                                    .forEach(compKey => {
                                        const safeCompKey = window.infinito.vao.controller.utils.safeCssName(compKey);
                                        const compValue = compPrices[compKey];
                                        const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                                            compValue,
                                            Constants.RATE_DIGITS,
                                            true,
                                            this.hotel.locale
                                        );
                                        const pickup = compPricesPickup[compKey];
                                        let labelColor = '';
                                        if ($.isNumeric(pickup)) {
                                            const pickupFloat = parseFloat(pickup);
                                            if (pickupFloat > 0) {
                                                labelColor = Constants.COLORS.HEX.SUCCESS;
                                            } else if (pickupFloat < 0) {
                                                labelColor = Constants.COLORS.HEX.DANGER;
                                            }
                                        }
                                        this.addCompNameColumn(safeCompKey, compKey);

                                        rowCells.push(this.acquireTableCell(stayDate, {
                                            field: safeCompKey,
                                            value: html`
                                                <vao-tag
                                                        label="${formatted}"
                                                        labelColor="${labelColor}"
                                                ></vao-tag>
                                            `
                                        }));
                                    });
                            }

                            if (
                                _procData.roomsOtb
                                && _procData.roomsOtb[stayDate]
                                && 'value' in _procData.roomsOtb[stayDate]
                                && $.isNumeric(_procData.roomsOtb[stayDate].value)
                            ) {
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.forecast,
                                    value: html`
                                        <vao-story-forecast-subtitle
                                                .recordDate="${this.recordDate}"
                                                .stayDate="${stayDate}"
                                                .hotel="${this.hotel}"
                                        ></vao-story-forecast-subtitle>
                                    `
                                }));
                            }
                            if (
                                _procData.roomsOtb
                                && _procData.roomsOtb[stayDate]
                                && 'value' in _procData.roomsOtb[stayDate]
                                && $.isNumeric(_procData.roomsOtb[stayDate].value)
                            ) {
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.fcrange,
                                    value: html`
                                        <vao-story-forecastrange-subtitle
                                                .recordDate="${this.recordDate}"
                                                .stayDate="${stayDate}"
                                                .hotel="${this.hotel}"
                                        ></vao-story-forecastrange-subtitle>
                                    `
                                }));
                            }

                            if (
                                _procData.roomsOtb
                                && _procData.roomsOtb[stayDate]
                                && 'value' in _procData.roomsOtb[stayDate]
                                && $.isNumeric(_procData.roomsOtb[stayDate].value)
                            ) {
                                rowCells.push(this.acquireTableCell(stayDate, {
                                    field: cellFieldMap.expectedPickup,
                                    value: html`
                                        <vao-story-what-to-expect-pickup-subtitle
                                                .recordDate="${this.recordDate}"
                                                .stayDate="${stayDate}"
                                                .hotel="${this.hotel}"
                                        ></vao-story-what-to-expect-pickup-subtitle>
                                    `
                                }));
                            }


                            /** ****========COMP PRICING COLUMN==========***** */
                            this.fn_getcomppricing(stayDate, rowCells, positiontext, CompsetAvgValue, myratevalue);
                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.comppricing,
                                value: '-'
                            }));

                            /** ****========MY PRICING COLUMN==========***** */

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.mypricing,
                                value: '-'
                            }));

                            /** ****======LAST RATE CHANGE COLUMN======***** */

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.lastratechange,
                                value: html`-`
                            }));

                            /** *****=======RATE GUIDANCE COLUMN=======***** */

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.rateguidance,
                                value: html`-`
                            }));

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.ratedecision,
                                value: html`-`
                            }));


                            // Below if is for Details -

                            rowCells.push(this.acquireTableCell(stayDate, {
                                field: cellFieldMap.details, // priceRankLeadSiblingPace,
                                value: html`
                                    <vao-button
                                            startIcon="${Constants.ICONS.ELLIPSIS}"
                                            variant="invert"
                                            color="secondary"
                                            size="xs"
                                            @click=${this.onClickDetails.bind(this, stayDate)}
                                    ></vao-button>`
                            }));


                            rows.push(new TableRow(rowCells));
                        }
                    });

                    this.rows = rows;
                    this.isLoading = false;
                }
            );
        });
    }

    fetchNotes(stayDate, hotels_id, rowCells, RateGuidance, notesString) {
        let dataObj = {
            hotel_id: hotels_id,
            stayDate: stayDate,
            op: 'getNotesCount',
            serviceName: 'desktop.php'
        };
        let flag = this;
        var openai_RateGuidance = RateGuidance;
        sendRequest(dataObj, function (res) {
            var jsonObj = JSON.parse(res);
            let counttext = jsonObj.data.count;
            let ratedata = jsonObj.data.ratedata;
            let counthtml = html`<span id="${stayDate}">${counttext}</span>`;

            if (counttext == 0) {
                counthtml = html`<span id="${stayDate}">${Constants.MISSING_STR}</span>`;
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html`
                        <vao-button
                                .text="${counthtml}"
                                variant="invert"
                                size="xs"
                                @click=${flag.onClickNotes.bind(this, stayDate, hotels_id, rowCells)}
                        ></vao-button>`
                }));
            } else {
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.notes,
                    value: html`
                        <vao-button
                                .text="${counthtml}"
                                variant="invert"
                                size="xs"
                                @click=${flag.onClickNotes.bind(this, stayDate, hotels_id, rowCells)}
                        ></vao-button>`

                }));
            }
            if (ratedata.rate_decision && ratedata.rate_decision != '') {
                let rate_decision = ratedata.rate_decision;
                let rate_guidance_answer = ratedata.rate_guidance_answer;

                // Checking the previous and current rate guidance and then deciding the color codings.
                var rateguidancematchcolor = 'light';
                var Agreecolor = 'success';
                var Disagreecolor = 'secondary';
                var Strategycolor = 'primary';
                if (rate_guidance_answer !== openai_RateGuidance) {
                    rateguidancematchcolor = 'dark';
                    Agreecolor = 'orange';
                    Disagreecolor = 'orange';
                    Strategycolor = 'orange';
                }

                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.ratedecision,
                    value: html`
                        <div>
                            <vao-button
                                    startIcon="${Constants.ICONS.TICKCRICLE}"
                                    tooltip="Agree"
                                    variant="invert"
                                    color="${rate_decision == 'Agree' ? Agreecolor : rateguidancematchcolor}"
                                    size="small"
                                    @click=${flag.onClickAgreerateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                            <vao-button
                                    startIcon="${Constants.ICONS.TIMESCIRCLE}"
                                    tooltip="Disagree"
                                    variant="invert"
                                    color="${rate_decision == 'Disagree' ? Disagreecolor : rateguidancematchcolor}"
                                    size="small"
                                    @click=${flag.onClickDisgreerateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                            <vao-button
                                    startIcon="${Constants.ICONS.YINYANG}"
                                    tooltip="Strategy"
                                    variant="invert"
                                    color="${rate_decision == 'Strategy' ? Strategycolor : rateguidancematchcolor}"
                                    size="small"
                                    @click=${flag.onClickStrategyrateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                        </div>`
                }));
            } else {
                rowCells.push(flag.acquireTableCell(stayDate, {
                    field: cellFieldMap.ratedecision,
                    value: html`
                        <div>
                            <vao-button
                                    startIcon="${Constants.ICONS.TICKCRICLE}"
                                    tooltip="Agree"
                                    variant="invert"
                                    color="light"
                                    size="small"
                                    @click=${flag.onClickAgreerateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                            <vao-button
                                    startIcon="${Constants.ICONS.TIMESCIRCLE}"
                                    tooltip="Disagree"
                                    variant="invert"
                                    color="light"
                                    size="small"
                                    @click=${flag.onClickDisgreerateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                            <vao-button
                                    startIcon="${Constants.ICONS.YINYANG}"
                                    tooltip="Strategy"
                                    variant="invert"
                                    color="light"
                                    size="small"
                                    @click=${flag.onClickStrategyrateDecision.bind(this, flag.hotel.id, stayDate, notesString, rowCells, flag, openai_RateGuidance)}
                            ></vao-button>
                        </div>`
                }));
            }
        });
    }

    fetchPosition(stayDate, recordDate, rowCells) {
        let normality = Constants.MISSING_STR;
        let normalitytext = '';
        const biStatistics = window.infinito.vao.model.biStatistics;
        biStatistics.fetchStatistics(
            this.hotel.id,
            biStatistics.buildQuery({
                recordDate: recordDate,
                firstStayDate: stayDate,
                lastStayDate: stayDate,
                fields: [
                    biStatistics.fields.priceRank,
                    biStatistics.fields.priceRankLeadSiblingPace
                ],
                pickupOffset: this.pickupSetting
            }),
            (data, procData) => {
                let typicalPriceRank = '';
                let _procData = procData;
                let priceRankLeadSiblingPace = ((_procData.priceRankLeadSiblingPace || {})[stayDate] || {}).value || null;

                if (
                    priceRankLeadSiblingPace
                    && typeof priceRankLeadSiblingPace === 'object'
                    && 'T0' in priceRankLeadSiblingPace
                    && 'setRank' in priceRankLeadSiblingPace.T0
                    && $.isNumeric(priceRankLeadSiblingPace.T0.setRank)
                ) {
                    let setLength = parseInt(priceRankLeadSiblingPace.T0.setLength);
                    let setRankSum = 0;
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T0.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T1.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T2.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T3.value || 0) || 0);
                    setRankSum += (parseInt(priceRankLeadSiblingPace.T4.value || 0) || 0);
                    if (setLength) {
                        let avg = +parseFloat(setRankSum / setLength)
                            .toFixed(0); // + to drop 0's
                        typicalPriceRank = avg + '';
                    } else {
                        typicalPriceRank = Constants.MISSING_STR;
                    }
                } else {
                    typicalPriceRank = Constants.MISSING_STR;
                }
                let rateRank = _procData.priceRank[stayDate].value;

                if ($.isNumeric(rateRank) && $.isNumeric(typicalPriceRank)) {
                    const diff = parseInt(rateRank) - parseInt(typicalPriceRank);

                    if (diff <= 1 && diff >= -1) {
                        normality = new StoryText('Typical', true, storyTextTypes.TYPE_NEUTRAL);
                        normalitytext = 'Typical';
                    } else if (diff > 0) {
                        normality = new StoryText('High', true, storyTextTypes.TYPE_POSITIVE);
                        normalitytext = 'High';
                    } else {
                        normality = new StoryText('Low', true, storyTextTypes.TYPE_NEGATIVE);
                        normalitytext = 'Low';
                    }
                }

                rowCells.push(this.acquireTableCell(stayDate, {
                    field: cellFieldMap.positioning,
                    value: storyHtml`${normality}`
                }));
                return normalitytext;
            }
        );
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (
            _changedProperties.has('hotel')
            || _changedProperties.has('recordDate')
            || _changedProperties.has('pickupSetting')
            || _changedProperties.has('startDate')
            || _changedProperties.has('endDate')
            || _changedProperties.has('dayofweekfilter')
        ) {
            this.fill();
        }
    }

    onChangeTableOpts(e) {
        if (e.detail.newTableOpts) {
            this.tableOpts = e.detail.newTableOpts; // Share obj with table to not cause re-render.
        }
    }

    dayofweekchange(e) {
        this.dayofweekfilter = e.target.value;
    }

    render() {
        document.getElementById('vao__desktop-weekday-Selection')
            .addEventListener('change', this.dayofweekchange);

        return html`
            <div class="vao__components--pageDesktopSingleTable">
                <vao-table
                        stateKey="${this.tableStateKey}"
                        .tableOpts="${this.tableOpts}"
                        .columns="${this.columns}"
                        .rows="${this.isLoading ? [new TableLoadingRow()] : this.rows.length > 0 ? this.rows : [new TableNoDataRow()]}"
                        @vao-table-tableopts-change="${this.onChangeTableOpts}"
                ></vao-table>
            </div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktopSingleTable = PageDesktopSingleTable;
customElements.define('vao-page-desktop-single-table', PageDesktopSingleTable);
