import { AppElement, html } from '../AppElement.js';
import { sendRequest } from '../App.common';
import Constants from '../Constants';
import { storyHtml, StoryText, storyTextTypes } from '../StoryTextElement';
import ColorArray from '../ColorArray';

const domSelectors = {
    page: '.vao__compIntelligence',
    prefaceContainer: '.vao__compIntelligence--prefaceContainer',
    prefaceLegendContainer: '.vao__compIntelligence--propLegendContainer',
    propLegendTemplate: '#vao__compIntelligence--propLegend-template',
    propLegend: '.vao__compIntelligence--propLegend',
    propLegendDot: '.vao__compIntelligence--dot',
    propLegendName: '.vao__compIntelligence--propLegend--propName',
    prefaceFilterContainer: '.vao__compIntelligence--filterContainer',
    prefaceFilter: '.vao__compIntelligence--filter',
    rateGraphContainer: '#vao__compIntelligence--rateGraphContainer',
    rateTableContainer: '#vao__compIntelligence--rateTableContainer',
    ratePaceGraphContainer: '#vao__compIntelligence--ratePaceGraphContainer',
    channel: 'vao__components--rateFilterDiv-channel'
};
let pageData = {};
const colorArray = ['#5DA5DA', '#FAA43A', '#60BD68', '#F17CB0', '#B2912F', '#B276B2', '#DECF3F', '#F15854', '#4D4D4D', '#FFB399', '#FF33FF', '#FFFF99', '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D', '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC', '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399', '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#1AFF33', '#999933', '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
let colorIndexCounter = 0;
let propNameToColorMap = {};
let nonformatedmonth = [];
let $filterPopup;
let $ratePerCompGraph = null;
let $ratePerCompTable = null;
let $ratePaceGraph = null;
let state = {};
let enterProp = null;

export const cellFieldMap = {
    stayDate: 'stayDate',
    roomsOtb: 'roomsOtb',
    revenue: 'revenue',
    adr: 'adr'
};

let paceDescMap = {
    1: 'strong',
    2: 'slightly ahead of pace',
    3: 'inline with pace',
    4: 'slightly behind pace',
    5: 'behind pace'
};

class pageMonthlyAnalysisModal extends AppElement {
    static get properties() {
        return {
            hotel: { type: Object }

        };
    }

    constructor() {
        super();
        this.hotelid = '';
        this.hotelname = '';
        this.hotel = window.infinito.vao.controller.storageHelper.getSelectedHotel();
        this.mastermonthselected = '';

        this.recordDate = window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = window.infinito.vao.controller.storageHelper.getPickupOffset();

        this.startDate = this.recordDate;
        this.endDate = window.infinito.vao.controller.dateHelper.addDaysToDateString(this.startDate, 30);
        this.otbDataPromise = null;
    }

    async channelOnChange(newChannel) {
        state = $.extend({}, state, {
            channelsId: newChannel
        });
        this.cleanupPageStatComponents();
        await this.coreEnter(state);
    }

    async getRulesdata() {
        // rulestable
        await this.paintpacerule();
    }

    async paintpacerule() {
        return new Promise((resolve, reject) => {
            try {
                $('#rulestable')
                    .find('tr:not(:first)')
                    .remove();
                document.getElementById('heightchanger').style.height = '110px';
                let newRow = document.getElementById('rulestable')
                    .insertRow();

                newRow.innerHTML = '<td style="background-color:white;">Loading Data...</td>';

                let dtpopup = document.getElementById('vao__select--month').value;

                let dataObj = {
                    // 'hotel_id': this.hotel.id,
                    hotel_id: this.hotelid,
                    recordDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                    stayDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                    offset: infinito.vao.controller.storageHelper.getPickupOffset(),
                    start_date: dtpopup,
                    op: 'getPaceruledata',
                    serviceName: 'ivirulesapi.php'
                };
                sendRequest(dataObj, async (res) => {
                    let jsonObj = JSON.parse(res);
                    const dates = jsonObj.data.rankData;
                    if (dates.length > 0) {
                        $('#rulestable')
                            .find('tr:not(:first)')
                            .remove();
                        document.getElementById('heightchanger').style.height = '500px';
                    }
                    const pmsdatasnapshotdata = jsonObj.data.pmsvalues.snapshotdata;
                    const pmsdatasnapoffsetdata = jsonObj.data.pmsvalues.offsetdata;
                    const marketvaluesdata = jsonObj.data.marketvalues;

                    await Promise.all(dates.map(async (stayDate, index) => {
                        try {
                            const prettyStayDate = window.infinito.vao.controller.dateHelper.getShortDayDateMonFullYearString(stayDate);
                            const whatchanged = '-';
                            let EXPECTEDPU = '-';

                            let OCC = Constants.MISSING_STR;
                            if (pmsdatasnapshotdata.occupancy && pmsdatasnapshotdata.occupancy[stayDate] && 'value' in pmsdatasnapshotdata.occupancy[stayDate] && $.isNumeric(pmsdatasnapshotdata.occupancy[stayDate].value)) {
                                OCC = this.formatPercentageValue(pmsdatasnapshotdata.occupancy[stayDate].value);
                            }

                            let FCRANGE = '-';
                            let MYPACE = '-';


                            let RMS = Constants.MISSING_STR;
                            if (pmsdatasnapshotdata.roomsOtb && pmsdatasnapshotdata.roomsOtb[stayDate] && 'value' in pmsdatasnapshotdata.roomsOtb[stayDate] && $.isNumeric(pmsdatasnapshotdata.roomsOtb[stayDate].value)) {
                                RMS = pmsdatasnapshotdata.roomsOtb[stayDate].value;
                            }

                            let REV = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(Constants.MISSING_STR, Constants.REVENUE_DIGITS, true, this.hotel.locale);

                            if (pmsdatasnapshotdata.revenueNet && pmsdatasnapshotdata.revenueNet[stayDate] && 'value' in pmsdatasnapshotdata.revenueNet[stayDate] && $.isNumeric(pmsdatasnapshotdata.revenueNet[stayDate].value)) {
                                REV = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(pmsdatasnapshotdata.revenueNet[stayDate].value, Constants.REVENUE_DIGITS, true, this.hotel.locale);
                            }

                            let ADR = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(Constants.MISSING_STR, Constants.ADR_DIGITS, true, this.hotel.locale, 1);

                            if (pmsdatasnapshotdata.adrNet && pmsdatasnapshotdata.adrNet[stayDate] && 'value' in pmsdatasnapshotdata.adrNet[stayDate] && $.isNumeric(pmsdatasnapshotdata.adrNet[stayDate].value)) {
                                ADR = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(pmsdatasnapshotdata.adrNet[stayDate].value, Constants.ADR_DIGITS, true, this.hotel.locale, 1);
                            }

                            let RMSPU = '-';
                            let REVPU = '-';
                            let ADRPU = '-';
                            let MYRATE = '-';
                            let COMPAVG = '-';
                            let POSITION = '-';


                            newRow = document.getElementById('rulestable')
                                .insertRow();
                            newRow.innerHTML = `<td style="position:sticky;left:0;z-index:1;background-color:white;">${prettyStayDate}</td><td id="whatchange_${index}">${whatchanged}</td><td id="expectedpu_${index}">${EXPECTEDPU}</td><td>${OCC}</td><td id="fcrange_${index}">${FCRANGE}</td><td id="mypace_${index}">${MYPACE}</td><td>${RMS}</td><td>${REV}</td><td>${ADR}</td><td id="rmspu_${index}">${RMSPU}</td><td id="revpu_${index}">${REVPU}</td><td id="adrpu_${index}">${ADRPU}</td><td id="myrate_${index}">${MYRATE}</td><td id="compavg_${index}">${COMPAVG}</td><td id="position_${index}">${POSITION}</td>`;


                            /* RMSPU */
                            const value = pmsdatasnapoffsetdata.roomsOtb[stayDate].value;
                            const valueInt = parseInt(value, 10);
                            const color = window.infinito.vao.controller.pickupHelper.getPickupColorFor(valueInt);
                            let bgColor = null;
                            if (color) {
                                bgColor = ColorArray.hexToRGBA(color, 0.15);
                            }
                            let makeColorClass;
                            let renderCaret;
                            let renderValue;
                            if (value < 0) {
                                makeColorClass = 'text-danger';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_DOWN + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else if (value > 0) {
                                makeColorClass = 'text-success';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_UP + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else {
                                makeColorClass = '';
                                renderCaret = '';
                            }
                            if (value != '') {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + value + '</div>';
                            } else {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + Constants.MISSING_STR + '</div>';
                            }
                            const numVarBlock = `<div class="vao__components--numericVarianceBlock ${makeColorClass}" style="display:inline-flex;align-items:center;">${renderCaret}
${renderValue}</div>`;
                            document.getElementById('rmspu_' + index).innerHTML = `<div class="vao__components--tag rounded" style="background-color:${bgColor}"><div class="vao__components--tag-label" style="padding:.1rem .25rem .1rem .25rem;">${numVarBlock}</div></div>`;

                            /* REVPU */
                            const revpuvalue = pmsdatasnapoffsetdata.revenueNet[stayDate].value;
                            const revpuformatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(revpuvalue, Constants.REVENUE_DIGITS, true, this.hotel.locale);
                            if (revpuvalue < 0) {
                                makeColorClass = 'text-danger';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_DOWN + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else if (revpuvalue > 0) {
                                makeColorClass = 'text-success';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_UP + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else {
                                makeColorClass = '';
                                renderCaret = '';
                            }
                            if (revpuvalue != '') {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + revpuformatted + '</div>';
                            } else {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + Constants.MISSING_STR + '</div>';
                            }
                            document.getElementById('revpu_' + index).innerHTML = '<div class="vao__components--numericVarianceBlock ' + makeColorClass + '" style="display:flex;align-items:center;">' + renderCaret + '\n' + renderValue + '</div>';


                            /* ADRPU */
                            const valueadr = pmsdatasnapoffsetdata.adrNet[stayDate].value;
                            const formattedadr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(valueadr, Constants.ADR_DIGITS, true, this.hotel.locale, 1);
                            if (valueadr < 0) {
                                makeColorClass = 'text-danger';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_DOWN + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else if (valueadr > 0) {
                                makeColorClass = 'text-success';
                                renderCaret = '<vao-icon cls="' + Constants.ICONS.CARET_UP + '" style="margin-right:.3rem;font-size:.7rem;"></vao-icon>';
                            } else {
                                makeColorClass = '';
                                renderCaret = '';
                            }
                            if (valueadr != '') {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + formattedadr + '</div>';
                            } else {
                                renderValue = '<div class="vao__components--numericVarianceBlock-val">' + Constants.MISSING_STR + '</div>';
                            }
                            document.getElementById('adrpu_' + index).innerHTML = '<div class="vao__components--numericVarianceBlock ' + makeColorClass + '" style="display:flex;align-items:center;">' + renderCaret + '\n' + renderValue + '</div>';

                            /* MYRATE COLUMN */
                            if (marketvaluesdata.rate && marketvaluesdata.rate[stayDate] && 'value' in marketvaluesdata.rate[stayDate]) {
                                document.getElementById('myrate_' + index).innerHTML = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(marketvaluesdata.rate[stayDate].value, Constants.RATE_DIGITS, true, this.hotel.locale);
                            }


                            /* COMP AVG */
                            if (marketvaluesdata.compAverageRate && marketvaluesdata.compAverageRate[stayDate] && 'value' in marketvaluesdata.compAverageRate[stayDate]) {
                                const compavgdiff = marketvaluesdata.compAverageRate[stayDate].value;
                                document.getElementById('compavg_' + index).innerHTML = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(compavgdiff, Constants.RATE_DIGITS, true, this.hotel.locale);
                            }

                            await Promise.all([this.fetchPaceWhatChanged(stayDate, index), this.fetchmypace(stayDate, this.recordDate, index), this.getforecastfromstaydate(stayDate, index), this.fetchPosition(stayDate, this.recordDate, index)]);
                        } catch (e) {
                            reject(e);
                        }
                    }));
                    resolve();
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async fetchPosition(stayDate, recordDate, row) {
        return new Promise((resolve, reject) => {
            try {
                stayDate = stayDate.substring(0, 10);
                let normality = Constants.MISSING_STR;
                const biStatistics = window.infinito.vao.model.biStatistics;
                biStatistics.fetchStatistics(this.hotelid, biStatistics.buildQuery({
                    recordDate: recordDate,
                    firstStayDate: stayDate,
                    lastStayDate: stayDate,
                    fields: [biStatistics.fields.priceRank, biStatistics.fields.priceRankLeadSiblingPace],
                    pickupOffset: this.pickupSetting
                }), (data, procData) => {
                    try {
                        let typicalPriceRank;
                        let _procData = procData;
                        let priceRankLeadSiblingPace = ((_procData.priceRankLeadSiblingPace || {})[stayDate] || {}).value || null;

                        if (priceRankLeadSiblingPace && typeof priceRankLeadSiblingPace === 'object' && 'T0' in priceRankLeadSiblingPace && 'setRank' in priceRankLeadSiblingPace.T0 && $.isNumeric(priceRankLeadSiblingPace.T0.setRank)) {
                            let setLength = parseInt(priceRankLeadSiblingPace.T0.setLength);
                            let setRankSum = 0;
                            setRankSum += (parseInt(priceRankLeadSiblingPace.T0.value || 0) || 0);
                            setRankSum += (parseInt(priceRankLeadSiblingPace.T1.value || 0) || 0);
                            setRankSum += (parseInt(priceRankLeadSiblingPace.T2.value || 0) || 0);
                            setRankSum += (parseInt(priceRankLeadSiblingPace.T3.value || 0) || 0);
                            setRankSum += (parseInt(priceRankLeadSiblingPace.T4.value || 0) || 0);
                            if (setLength) {
                                let avg = +parseFloat(setRankSum / setLength)
                                    .toFixed(0); // + to drop 0's
                                typicalPriceRank = avg + '';
                            } else {
                                typicalPriceRank = Constants.MISSING_STR;
                            }
                        } else {
                            typicalPriceRank = Constants.MISSING_STR;
                        }
                        let rateRank = _procData.priceRank[stayDate].value;

                        if ($.isNumeric(rateRank) && $.isNumeric(typicalPriceRank)) {
                            const diff = parseInt(rateRank) - parseInt(typicalPriceRank);

                            if (diff <= 1 && diff >= -1) {
                                normality = new StoryText('Typical', true, storyTextTypes.TYPE_NEUTRAL);
                            } else if (diff > 0) {
                                normality = new StoryText('High', true, storyTextTypes.TYPE_POSITIVE);
                            } else {
                                normality = new StoryText('Low', true, storyTextTypes.TYPE_NEGATIVE);
                            }
                        }

                        let position = storyHtml`${normality}`;
                        document.getElementById('position_' + row).innerHTML = position.strings[0] + position.values[0] + position.strings[1];
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async fetchmypace(stayDate, recordDate, row) {
        return new Promise((resolve, reject) => {
            try {
                stayDate = stayDate.substring(0, 10);
                let modelBiStats = window.infinito.vao.model.biStatistics;
                modelBiStats.fetchStatistics(this.hotelid, modelBiStats.buildQuery({
                    recordDate: recordDate,
                    firstStayDate: stayDate,
                    lastStayDate: stayDate,
                    fields: [modelBiStats.fields.roomsOtb, modelBiStats.fields.roomsOtbLeadMedian],
                    evolutionRange: 14
                }), (data, procData) => {
                    try {
                        let otbVal = ((procData.roomsOtb || {})[stayDate] || {}).value || null;
                        let otbLeadMedVal = ((procData.roomsOtbLeadMedian || {})[stayDate] || {}).value || null;

                        // Put these here to help batch lit async DOM update
                        this.dow = window.infinito.vao.controller.dateHelper.getFullDayString(stayDate);
                        this.leadTime = window.infinito.vao.controller.dateHelper.calcDaysBetweenDates(this.stayDate, this.recordDate);
                        let rmsPaceDesc;
                        let rmsPaceVar;

                        if ($.isNumeric(otbVal) && typeof otbLeadMedVal === 'object' && 'median' in otbLeadMedVal && $.isNumeric(otbLeadMedVal.median)) {
                            let diff = otbVal - otbLeadMedVal.median;
                            rmsPaceVar = Math.abs(diff);
                            if (diff < 0) {
                                rmsPaceDesc = 'behind';
                            } else if (diff > 0) {
                                rmsPaceDesc = 'ahead';
                            } else {
                                rmsPaceDesc = 'inline';
                            }
                        } else {
                            rmsPaceDesc = Constants.MISSING_STR;
                            rmsPaceVar = Constants.MISSING_STR;
                        }

                        let ab = storyHtml`${new StoryText(rmsPaceDesc, true, storyTextTypes.TYPE_TEXTUAL)}`;
                        let ab1 = storyHtml`${new StoryText(rmsPaceVar, true, storyTextTypes.TYPE_NEUTRAL)}`;
                        document.getElementById('mypace_' + row).innerHTML = ab.strings[0] + ab.values[0] + ab.strings[1] + ' of pace ' + ab1.strings[0] + ab1.values[0] + ab1.strings[1] + ' rooms';
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    async getforecastfromstaydate(stayDate, row) {
        return new Promise((resolve, reject) => {
            try {
                let modelBiStats = window.infinito.vao.model.biStatistics;
                modelBiStats.fetchStatistics(this.hotelid, modelBiStats.buildQuery({
                    recordDate: this.recordDate,
                    firstStayDate: stayDate,
                    lastStayDate: stayDate,
                    fields: [modelBiStats.fields.occupancyLeaderCloserTrendProphecy, modelBiStats.fields.roomsOtbLeaderCloserTrendProphecy]
                }), (data, procData) => {
                    try {
                        const _procData = procData || {};
                        let occProphecy = ((_procData.occupancyLeaderCloserTrendProphecy || {})[stayDate] || {}).value || null;
                        let roomsOtbLeaderCloserTrendProphecy = ((procData.roomsOtbLeaderCloserTrendProphecy || {})[stayDate] || {}).value || {};

                        /* EXPECTED PU */
                        let subtitle;
                        let badgeClass;
                        if (typeof roomsOtbLeaderCloserTrendProphecy === 'object' && $.isNumeric(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance)) {
                            const whatToExpect = Math.floor(roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance);
                            if (roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance < 0) {
                                subtitle = `${whatToExpect} decrease`;
                                badgeClass = 'badge-danger';
                            } else if (roomsOtbLeaderCloserTrendProphecy.leadersClosersVariance > 0) {
                                subtitle = `${whatToExpect} increase`;
                                badgeClass = 'badge-success';
                            } else {
                                subtitle = 'flat';
                                badgeClass = 'badge-primary';
                            }
                        } else {
                            subtitle = Constants.MISSING_STR;
                        }
                        document.getElementById('expectedpu_' + row).innerHTML = '<span class="badge ' + badgeClass + '">' + subtitle + '</span>';


                        /* FC RANGE */
                        let forecast;
                        if (typeof occProphecy === 'object' && $.isNumeric(occProphecy.leadersClosersProphecy)) {
                            badgeClass = 'font-size: 13.6px;';
                            let fForecast = parseFloat((occProphecy.leadersClosersProphecy || Constants.MISSING_STR));

                            if (fForecast < 0.29) {
                                forecast = 'Below 29%';
                                badgeClass = 'color:#030289;';
                            } else if (fForecast > 0.29 && fForecast < 0.39) {
                                badgeClass = 'color:#3e2f6b;';
                                forecast = '30-39%';
                            } else if (fForecast >= 0.39 && fForecast < 0.49) {
                                forecast = '40-49%';
                                badgeClass = 'color:#67527c;';
                            } else if (fForecast >= 0.49 && fForecast < 0.59) {
                                forecast = '50-59%';
                                badgeClass = 'color:#916e40;';
                            } else if (fForecast >= 0.59 && fForecast < 0.69) {
                                forecast = '60-69%';
                                badgeClass = 'color:#c89724;';
                            } else if (fForecast >= 0.69 && fForecast < 0.79) {
                                forecast = '70-79%';
                                badgeClass = 'color:#f3b80d;';
                            } else if (fForecast >= 0.79 && fForecast < 0.89) {
                                forecast = '80-89%';
                                badgeClass = 'color:#c4ba17;';
                            } else if (fForecast >= 0.89 && fForecast < 0.95) {
                                forecast = '90-94%';
                                badgeClass = 'color:#99b524;';
                            } else if (fForecast > 0.95 && fForecast <= 1) {
                                forecast = '95-100%';
                                badgeClass = 'color:#4aab3b;';
                            } else {
                                forecast = '101+%';
                                badgeClass = 'color:green;';
                            }
                        } else {
                            forecast = Constants.MISSING_STR;
                            badgeClass = 'color:black;';
                        }
                        document.getElementById('fcrange_' + row).innerHTML = '<div class="vao__components--storyForecastSubtitle"  style="' + badgeClass + '">' + forecast + '</div>';
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    formatPercentageValue(unformattedValue) {
        let percent = parseFloat(unformattedValue * 100);
        if (!Number.isNaN(percent)) {
            return percent.toFixed(1) + '%';
        }
        return unformattedValue;
    }

    async fetchPaceWhatChanged(stayDate, row) {
        return new Promise((resolve, reject) => {
            try {
                stayDate = stayDate.substring(0, 10);
                let modelBiStats = window.infinito.vao.model.biStatistics;
                modelBiStats.fetchStatistics(this.hotelid, modelBiStats.buildQuery({
                    recordDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                    firstStayDate: stayDate,
                    lastStayDate: stayDate,
                    fields: [modelBiStats.fields.revenueLeadSiblingPace, modelBiStats.fields.roomsOtbLeadSiblingPaceEvolution],
                    evolutionRange: 14
                }), (data, procData) => {
                    try {
                        let revLeadSibPaceVal = ((procData.revenueLeadSiblingPace || {})[stayDate] || {}).value || null;
                        let otbLeadSibPaceEvoVal = ((procData.roomsOtbLeadSiblingPaceEvolution || {})[stayDate] || {}).value || null;

                        if (typeof revLeadSibPaceVal === 'object' && 'T0' in revLeadSibPaceVal && 'setDenseRank' in revLeadSibPaceVal.T0 && 'setDenseLength' in revLeadSibPaceVal.T0) {
                            this.paceDesc = window.infinito.vao.controller.analyseAttributesHelper.scaleRankMapForUniqueLength(paceDescMap, revLeadSibPaceVal.T0.setDenseLength)[revLeadSibPaceVal.T0.setDenseRank] || Constants.MISSING_STR;
                        } else {
                            this.paceDesc = Constants.MISSING_STR;
                        }

                        if (typeof otbLeadSibPaceEvoVal === 'object') {
                            let sorted = Object.values(otbLeadSibPaceEvoVal)
                                .sort((a, b) => {
                                    return a.leadTime - b.leadTime;
                                });
                            let now = sorted[0];
                            if (typeof now.value === 'object' && 'T0' in now.value && 'setDenseRank' in now.value.T0 && 'setDenseLength' in now.value.T0 && $.isNumeric(now.value.T0.setDenseRank)) {
                                let nowRank = now.value.T0.setDenseRank;
                                let nowLength = now.value.T0.setDenseLength;
                                let thenRank = null;
                                let thenLength = null;
                                let i;
                                for (i = 1; i <= 14; i++) {
                                    let then = sorted[i] || null;
                                    if (typeof then === 'object' && 'value' in then && 'T0' in then.value && 'setDenseRank' in then.value.T0 && 'setDenseLength' in then.value.T0 && $.isNumeric(then.value.T0.setDenseRank)) {
                                        thenRank = then.value.T0.setDenseRank;
                                        thenLength = then.value.T0.setDenseLength;
                                    }
                                    if (thenRank !== null && thenRank !== nowRank) {
                                        break;
                                    }
                                }
                                this.paceDiffInterval = i;
                                if (nowRank !== null) {
                                    this.paceTo = window.infinito.vao.controller.analyseAttributesHelper
                                        .scaleRankMapForUniqueLength(paceDescMap, nowLength)[nowRank] || Constants.MISSING_STR;
                                } else {
                                    this.paceTo = Constants.MISSING_STR;
                                }
                                if (thenRank !== null) {
                                    this.paceFrom = window.infinito.vao.controller.analyseAttributesHelper
                                        .scaleRankMapForUniqueLength(paceDescMap, thenLength)[thenRank] || Constants.MISSING_STR;
                                } else {
                                    this.paceFrom = Constants.MISSING_STR;
                                }
                                if (nowRank && thenRank && nowRank !== thenRank) {
                                    if (nowRank < thenRank) {
                                        this.paceDiff = 'Pace improved';
                                    } else if (nowRank > thenRank) {
                                        this.paceDiff = 'Pace fallen behind';
                                    } else {
                                        this.paceDiff = 'Pace not changed';
                                    }
                                } else {
                                    this.paceDiff = Constants.MISSING_STR;
                                }
                            } else {
                                this.paceDiff = Constants.MISSING_STR;
                                this.paceDiffInterval = Constants.MISSING_STR;
                                this.paceFrom = Constants.MISSING_STR;
                                this.paceTo = Constants.MISSING_STR;
                            }
                        } else {
                            this.paceDiff = Constants.MISSING_STR;
                            this.paceDiffInterval = Constants.MISSING_STR;
                            this.paceFrom = Constants.MISSING_STR;
                            this.paceTo = Constants.MISSING_STR;
                        }
                        let ab = storyHtml`${new StoryText(this.paceDiff, true, storyTextTypes.TYPE_TEXTUAL)}`;
                        document.getElementById('whatchange_' + row).innerHTML = ab.strings[0] + ab.values[0] + ab.strings[1];
                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    /* OTB TABLE BEGINS */
    async getOTBdata(selectedmonth) {
        this.otbDataPromise = new Promise((resolve, reject) => {
            try {
                $('#otbtable')
                    .find('tr:not(:first)')
                    .remove();
                this.isLoading = true;
                this.rows = [];

                let dataObj = {
                    hotel_id: this.hotelid,
                    recordDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                    month: selectedmonth,
                    offset: infinito.vao.controller.storageHelper.getPickupOffset(),
                    op: 'getOTBdata',
                    serviceName: 'monthlyAnalysis.php'
                };

                sendRequest(dataObj, (res) => {
                    try {
                        $('#otbtable')
                            .find('tr:not(:first)')
                            .remove();

                        let jsonObj = JSON.parse(res);
                        jsonObj = jsonObj.data;
                        let ln = jsonObj.length;
                        if (ln > 0) {
                            for (let i = 0; i < ln; i++) {
                                let newRow = document.getElementById('otbtable')
                                    .insertRow();
                                let {
                                    formattedadr,
                                    formattedrevenue,
                                    rms
                                } = this.extractOtbValues(jsonObj[i]);

                                if (i === 0) {
                                    newRow.innerHTML = '<td style="font-weight:bold;">' + jsonObj[i].stayDate + '(as of ' + jsonObj[i].asof + ')</td><td style="font-weight:bold;">' + rms + '</td><td style="font-weight:bold;">' + formattedadr + '</td><td style="font-weight:bold;">' + formattedrevenue + '</td>';
                                } else {
                                    newRow.innerHTML = '<td>' + jsonObj[i].stayDate + '(as of ' + jsonObj[i].asof + ')</td><td>' + rms + '</td><td>' + formattedadr + '</td><td>' + formattedrevenue + '</td>';
                                }
                            }
                        } else {
                            let newRow = document.getElementById('otbtable')
                                .insertRow();
                            newRow.innerHTML = '<tr>No Data Available.</tr>';
                        }
                        resolve(jsonObj);
                    } catch (e) {
                        reject(e);
                    }
                });
            } catch (e) {
                reject(e);
            }
        });

        return this.otbDataPromise;
    }

    extractOtbValues(otbResponseItem) {
        let formattedadr = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(otbResponseItem.adr, Constants.ADR_DIGITS, true, this.hotel.locale, 1);
        let formattedrevenue = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(otbResponseItem.revenue, Constants.REVENUE_DIGITS, true, this.hotel.locale);


        let rms = otbResponseItem.rooms;
        if (otbResponseItem.rooms === null || otbResponseItem.rooms === 'null' || otbResponseItem.rooms == null) {
            rms = '-';
        }

        if (formattedadr === null || formattedadr === 'null' || formattedadr == null) {
            formattedadr = '-';
        }

        if (formattedrevenue === null || formattedrevenue === 'null' || formattedrevenue == null) {
            formattedrevenue = '-';
        }
        return {
            formattedadr,
            formattedrevenue,
            rms
        };
    }

    async monthChange(e) {
        let offset = infinito.vao.controller.storageHelper.getPickupOffset()
            .toString();
        offset = offset.substr(offset.length - 1);
        document.getElementById('ruletabletitle').innerHTML = 'Changes vs ' + offset + ' days ago';
        this.mastermonthselected = e;

        let selectedHotelId = this.hotelid;// infinito.vao.controller.storageHelper.getSelectedHotelId();
        let channelSelectEl = new window.vao.components.ChannelSelect({
            selectedChannelId: infinito.vao.controller.channelHelper.getPrimaryChannelsId(),
            channels: infinito.vao.controller.channelHelper.getChannels()
        });
        channelSelectEl.addEventListener('vao-channel-select-on-change', (e) => {
            let newChannelId = e.detail.newValue;
            this.channelOnChange(newChannelId);
        });
        document.getElementById(domSelectors.channel).innerText = '';
        document.getElementById(domSelectors.channel)
            .appendChild(channelSelectEl);

        if (enterProp !== selectedHotelId) {
            this.cleanupPageComponents();
            state = {};
            pageData = {};
        }

        enterProp = selectedHotelId;

        pageData = {};
        state.shouldSkipFilter = false;
        this.cleanupPageComponents();

        state.range = 30;
        state.rangeCurrent = 30;


        $(domSelectors.page)
            .on('click', domSelectors.prefaceFilter, () => {
                infinito.components.rateFilterPopup.show($filterPopup);
            });
        $(domSelectors.page)
            .on('click', domSelectors.propLegend, (e) => {
                let $target = $(e.target)
                    .closest(domSelectors.propLegend);
                if ($target.hasClass('disabled')) {
                    this.showPropLegend($target, true, true);
                } else {
                    this.hidePropLegend($target, true, true);
                }
            });

        $('#vao__btn--runanalysis').prop('hidden', false);
        document.getElementById('vao__component--openapiresponse').innerText = '';


        await Promise.all([
            this.getOTBdata(this.mastermonthselected),
            this.getRulesdata(),
            this.coreEnter(state)
        ]);
    }

    render() {
        return html`
            <style>
                .animate-charcter {

                    background-image: linear-gradient(
                            -225deg,
                            #231557 0%,
                            #44107a 29%,
                            #ff1361 67%,
                            #fff800 100%
                    );
                    background-size: 200% auto;
                    color: #fff;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    animation: textclip 2s linear infinite;
                    display: inline-block;
                }

                @keyframes textclip {
                    to {
                        background-position: 200% center;
                    }
                }

            </style>

            <div class="container">
                <div class="row">
                    <div class="col-auto">
                        <select class="form-control" id="vao__select--month">
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="vao__flash--pickupPerMonth-title" style="text-align: center;color:#373737;">OTB vs prior
                        month Pace position
                    </div>

                    <div class="vao__component--pickupPerMonthTable" style="overflow: auto;">
                        <table id="otbtable" class="table">
                            <thead>
                            <tr>
                                <th scope="col" style="background: #343a40;color: #fff;">Stay Date</th>
                                <th scope="col" style="background: #343a40;color: #fff;">Rooms</i></th>
                                <th scope="col" style="background: #343a40;color: #fff;">ADR</th>
                                <th scope="col" style="background: #343a40;color: #fff;">Revenue</th>
                            </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="row">
                <div class="col-12">
                    <div class="vao__flash--pickupPerMonth-title"
                         style="text-align: center;color:#373737;font-weight: bold;">Analysis
                    </div>
                    <p id="vao__component--openapiresponseLoader" style="display:none;text-align: center;"><span
                            class="animate-charcter">Revenue analysis running, this can take up to 20 Sec </span>😊</p>
                    <p id="vao__component--openapiresponse" style="margin-top: 18px;"></p>
                    <button class="vao__components--button btn btn-primary text" id="vao__btn--runanalysis"
                            style="margin: 0 auto;"><i class="fas fa-play" aria-hidden="true"
                                                       style="margin-top: 4px;margin-right: 10px;font-size: 15px;"></i>
                        Run Analysis
                    </button>
                </div>
            </div>
            <hr/>
            <div class="vao__compIntelligence container">
                <div class="row">
                    <div class="col-12">
                        <div class=" vao__components--rateFilterDiv-opt" style="margin: 0;">
                            <div class="col-4">
                                <div class="vao__components--rateFilterDiv-optLabel">Channel</div>
                            </div>
                            <div class="col-8">
                                <div id="vao__components--rateFilterDiv-channel"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="vao-border-rad ov-no">
                            <div id="vao__compIntelligence--rateGraphContainer"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="vao-border-rad ov-no">
                            <div id="vao__compIntelligence--ratePaceGraphContainer"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="vao__flash--pickupPerMonth-title" style="text-align: center;color:#373737;"
                             id="ruletabletitle">Rules Table
                        </div>
                        <div class="vao__component--pickupPerMonthTable" id="heightchanger"
                             style="height: 500px;overflow: auto;">
                            <table id="rulestable" class="table">
                                <thead>
                                <tr style="position: sticky;top: 0;z-index: 2;">
                                    <th scope="col"
                                        style="position:sticky;left:0;z-index:1;background: #343a40;color: #fff;">Stay
                                        Date
                                    </th>
                                    <th scope="col" style="background: #343a40;color: #fff;">What Changed</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Expected PU</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Occupancy</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">FC Range</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">My Pace</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Rms</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Rev</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">ADR</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Rms PU</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Rev PU</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">PU ADR</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">My Rate</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Comp Avg</th>
                                    <th scope="col" style="background: #343a40;color: #fff;">Positioning</th>
                                </tr>
                                </thead>
                                <tbody>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>`;
    }

    async firstUpdated() {
        this.hotelid = document.getElementById('hid').value;
        this.hotelname = document.getElementById('hname').value;
        let monthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        let months = [];
        nonformatedmonth = [];
        let d = new Date(infinito.vao.controller.storageHelper.getCurrentDate());
        d.setDate(1);

        for (let i = 0; i < 12; i++) {
            let mn = d.getMonth() + 1;
            if (mn > 9) {
                nonformatedmonth.push(d.getFullYear() + '-' + mn);
            } else {
                nonformatedmonth.push(d.getFullYear() + '-0' + mn);
            }

            months.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
            d.setMonth(d.getMonth() + 1);
        }
        /* vao__select--month */
        for (let i = 0; i < 12; i++) {
            $('#vao__select--month')
                .append($('<option></option>')
                    .attr('value', nonformatedmonth[i])
                    .text(months[i]));
        }
        this.mastermonthselected = nonformatedmonth[0];


        $('#vao__select--month')
            .on('change', (e) => {
                this.monthChange(e.target.value);
            });

        $('#vao__btn--runanalysis')
            .on('click', () => this.onRunAnalysis());

        await this.monthChange(this.mastermonthselected);
    }

    async onRunAnalysis() {
        try {
            $('#vao__btn--runanalysis')
                .prop('disabled', true);

            document.getElementById('vao__component--openapiresponseLoader').style.display = 'block';

            let lastyearRms = 0;
            let lastyearADR = 0;
            let lastyearRevenue = 0;
            let today = new Date(infinito.vao.controller.storageHelper.getCurrentDate());
            let curyear = today.getFullYear();
            let curyearMonth = today.getMonth() + 1;
            let curyearDay = today.getDate();
            let lastYear = curyear - 1;

            if ((curyearMonth === 2) && (curyearDay === 29)) {
                curyearDay = 28;
            }
            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

            let lastYearDisplay = ('0000' + lastYear.toString()).slice(-4) + '-' + ('00' + curyearMonth.toString()).slice(-2) + '-' + ('00' + curyearDay.toString()).slice(-2);
            let monthandyearname;
            let monthname;

            const monthBreakdownPromise = new Promise((resolve, reject) => {
                infinito.vao.model.biStatistics.fetchStatistics(this.hotelid, infinito.vao.model.biStatistics.buildQuery({
                    recordDate: lastYearDisplay,
                    fields: [infinito.vao.model.biStatistics.fields.monthBreakdown]
                }), (data, monthBreakdownResponse) => {
                    if (data instanceof Error) {
                        reject(data);
                    } else {
                        resolve({
                            data,
                            monthBreakdownResponse
                        });
                    }
                });
            });


            let variancePostData = {
                // 'hotel_id': this.hotel.id,
                hotel_id: this.hotelid,
                recordDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                month: this.mastermonthselected,
                op: 'getOTBdataForVariance',
                serviceName: 'monthlyAnalysis.php'
            };

            const variancePromise = new Promise((resolve, reject) => {
                sendRequest(variancePostData, (res) => {
                    if (res instanceof Error) {
                        reject(res);
                    } else {
                        resolve({ varianceResponse: res });
                    }
                });
            });

            let flashPostData = {
                hotel_id: this.hotelid,
                recordDate: infinito.vao.controller.storageHelper.getCurrentDate(),
                yearMonKey: this.mastermonthselected,
                offset: infinito.vao.controller.storageHelper.getPickupOffset(),
                isoffset: 0,
                isbufc: 1,
                isLy: 1,
                op: 'getFlashHotelPickupPerMonthOcc',
                serviceName: 'flashsingleapi.php'
            };

            const flashPromise = new Promise((resolve, reject) => {
                sendRequest(flashPostData, (res) => {
                    if (res instanceof Error) {
                        reject(res);
                    } else {
                        resolve({ flashResponse: res });
                    }
                });
            });

            const [
                otbData, { monthBreakdownResponse }, { varianceResponse }, { flashResponse }
            ] = await Promise.all([
                this.otbDataPromise,
                monthBreakdownPromise,
                variancePromise,
                flashPromise
            ]);


            let val;
            let gofurther = 0;
            if (typeof monthBreakdownResponse !== 'object' || !('monthBreakdown' in monthBreakdownResponse) || typeof monthBreakdownResponse.monthBreakdown !== 'object' || typeof monthBreakdownResponse.monthBreakdown.value !== 'object') {
                gofurther = 1;
            }

            if (gofurther === 0) {
                val = monthBreakdownResponse.monthBreakdown.value;
                Object.keys(val)
                    .forEach((rowKey) => {
                        let rowData = val[rowKey];
                        lastyearRms = parseFloat(lastyearRms) + parseFloat(rowData.otb);
                        lastyearRevenue = parseFloat(lastyearRevenue) + parseFloat(rowData.revenue);
                    });
                lastyearADR = lastyearRevenue / lastyearRms;
            }

            let varianceObj = JSON.parse(varianceResponse);

            let obj = JSON.parse(flashResponse);
            let OTB = obj.data.roomsOtb;
            let Rev = obj.data.revenueNet;

            let forecastOtb = Math.abs(OTB - obj.data.Bfc.bfcMTDtotalOtb);
            let forecastRev = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(Math.abs(Rev - obj.data.Bfc.bfcMTDtotalRev), 0, true);

            let budgetOtb = Math.abs(OTB - obj.data.Bu.BMTDtotalOtb);
            let budgetRev = infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(Math.abs(Rev - obj.data.Bu.BMTDtotalRev), 0, true);


            /** *Variance to 1st of month** */
            today = new Date(this.mastermonthselected);
            curyear = today.getFullYear();
            curyearMonth = today.getMonth() + 1;
            curyearDay = today.getDate();
            lastYear = curyear - 1;

            if ((curyearMonth === 2) && (curyearDay === 29)) {
                curyearDay = 28;
            }

            lastYearDisplay = ('0000' + lastYear.toString()).slice(-4) + '-' + ('00' + curyearMonth.toString()).slice(-2) + '-' + ('00' + curyearDay.toString()).slice(-2);
            monthandyearname = monthNames[today.getMonth()] + '-' + ('0000' + lastYear.toString()).slice(-4);
            monthname = monthNames[today.getMonth()];

            let openAiContent = '';

            let otbDataLength = otbData.length;
            if (otbDataLength > 0) {
                openAiContent = '\nPace Performance\n';
                for (let i = 0; i < otbDataLength; i++) {
                    let {
                        formattedadr,
                        formattedrevenue,
                        rms
                    } = this.extractOtbValues(otbData[i]);

                    if (i === 0) {
                        openAiContent += `Current month ${otbData[i].stayDate} as of ${otbData[i].asof} Rms ${rms} Adr ${formattedadr} Revenue ${formattedrevenue}\n`;
                    } else {
                        openAiContent += `Previous month ${otbData[i].stayDate} as of ${otbData[i].asof} Rms ${rms} Adr ${formattedadr} Revenue ${formattedrevenue}\n`;
                    }
                }
            }

            /** *Forecast Budget and actual performance** */
            openAiContent += '\nForecast Budget and actual performance\n';
            openAiContent += `Need to pick up ${forecastOtb} rooms at a rate of ${forecastRev} to hit forecast and Need to pick up ${budgetOtb} rooms at a rate of ${budgetRev} to hit budget.\n`;

            // varianceObj
            openAiContent += '\nVariance to 1st of month\n';
            openAiContent += `Variance for ${monthname} compared to 1st of month Rms ${varianceObj.data.otb} ADR ${varianceObj.data.Adr} Revenue ${varianceObj.data.Rev}\n`;

            /** *Performance vs Same time Last year** */
            openAiContent += '\nPerformance vs Same time Last year\n';
            openAiContent += `${monthandyearname} as of ${lastYearDisplay} Rms ${lastyearRms} ADR ${lastyearADR} Revenue ${lastyearRevenue}\n`;

            /** *Final performance last year** */
            openAiContent += '\nFinal performance last year\n';
            openAiContent += `${monthandyearname} final performance Rms ${obj.data.Lydata.roomsOtb} ADR ${obj.data.Lydata.adrNet} Revenue ${obj.data.Lydata.totalrevenueNet}`;

            const prompt = `Your role: You are an intelligent revenue management analyst. You are fully versed in revenue management and hospitality jargon. Your communication style is ${this.getItemWithFallback('analysis_com_style', 'Objective')}. Your reply language is ${this.getItemWithFallback('analysis_lang', 'English')}. If language is not English please use ${this.getItemWithFallback('analysis_adr', 'ADR')} for ADR(Average Daily Rate),use ${this.getItemWithFallback('analysis_pace', 'Pace')} for Pace, use ${this.getItemWithFallback('analysis_occ', 'Occupancy')} for Occupancy.Your primary job is to analyze, summarize and explain the data breaking it down into sections with 3 sentences each section: Pace against prior months, Variance to Start of the month, Pace against same time last year, Current performance to Budget and Forecast.\n${openAiContent}`;

            let requestDataObj = {
                'prompt': prompt,
                'op': 'sendPrompt',
                'serviceName': "chatgptapi.php"
            };
            sendRequest(requestDataObj, function (res) {
                var jsonObj = JSON.parse(res);
                console.log(jsonObj);

                document.getElementById('vao__component--openapiresponse').innerText = jsonObj['data'];
            });

        } catch (e) {
            document.getElementById('vao__component--openapiresponse').innerText = 'Failed to get the analysis. Please try again later.';

            throw e;
        } finally {
            document.getElementById('vao__component--openapiresponseLoader').style.display = 'none';
            $('#vao__btn--runanalysis').prop('hidden', true);
            $('#vao__btn--runanalysis').prop('disabled', false);
        }
    }


    getItemWithFallback(item, fallback) {
        const value = localStorage.getItem(item);
        if (value === 'null' || value == null) {
            return fallback;
        }

        return value;
    }

    /* RATE GRAPHS BEGINS */
    cleanupPageComponents() {
        this.cleanupPageFilterComponents();
        this.cleanupPageStatComponents();
    }

    cleanupPageStatComponents() {
        if ($ratePerCompGraph) {
            infinito.components.ratePerCompGraph.destroy($ratePerCompGraph);
            $ratePerCompGraph = null;
        }
        if ($ratePerCompTable) {
            infinito.components.ratePerCompTable.destroy($ratePerCompTable);
            $ratePerCompTable = null;
        }
        if ($ratePaceGraph) {
            infinito.components.ratePaceGraph.destroy($ratePaceGraph);
            $ratePaceGraph = null;
        }
    }

    cleanupPageFilterComponents() {
        if ($filterPopup) {
            infinito.components.rateFilterPopup.dispose($filterPopup);
            $filterPopup.remove();
            $filterPopup = null;
        }
    }

    async coreEnter(opts, skipPreface) {
        let scopedOpts = opts || state; // take input priority - else grab page state
        await this.fetchPageData(scopedOpts);
        if (!skipPreface) {
            colorIndexCounter = 0;
            propNameToColorMap = {};

            this.emptyPrefaceLegends();

            this.fillPreface(scopedOpts);

            this.fillRateGraph(scopedOpts);
            this.fillRatePaceGraph(scopedOpts);
        } else {
            this.fillRateGraph(scopedOpts);
            this.fillRatePaceGraph(scopedOpts);
        }
    }

    showPropLegend($propLegend, updateGraph, updateTable) {
        let propName = $propLegend.find(domSelectors.propLegendName)
            .text();
        $propLegend.removeClass('disabled');
        if (updateGraph) {
            infinito.components.ratePerCompGraph.showSeries($ratePerCompGraph, propName);
        }
        if (updateTable) {
            infinito.components.ratePerCompTable.showScope($ratePerCompTable, propName);
        }
    }

    hidePropLegend($propLegend, updateGraph, updateTable) {
        let propName = $propLegend.find(domSelectors.propLegendName)
            .text();
        $propLegend.addClass('disabled');
        if (updateGraph) {
            infinito.components.ratePerCompGraph.hideSeries($ratePerCompGraph, propName);
        }
        if (updateTable) {
            infinito.components.ratePerCompTable.hideScope($ratePerCompTable, propName);
        }
    }

    fillRateGraph(graphOpts) {
        let date = new Date(this.mastermonthselected);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        firstDay = this.formatedate(firstDay);
        lastDay = this.formatedate(lastDay);
        if (this.mastermonthselected == nonformatedmonth[0]) {
            firstDay = infinito.vao.controller.storageHelper.getCurrentDate();
        }
        graphOpts = $.extend({}, graphOpts, {
            firstStayDate: firstDay,
            lastStayDate: lastDay
        });
        let joinedOpts = $.extend({}, {
            data: pageData,
            colorArray: colorArray,
            getRateColor: this.obtainPropNameColorAndAddToLegendIfNotExist,
            container: domSelectors.rateGraphContainer,
            ratePerCompGraphLegendEnabled: true,
            ratePerCompGraphSeriesShow: (seriesTarget) => {
                let name = seriesTarget.target.name;
                let selector = domSelectors.propLegendName + ':contains(' + name + ')';
                let $target = $(selector);
                if ($target.length) {
                    this.showPropLegend($target.closest(domSelectors.propLegend), false, true);
                }
            },
            ratePerCompGraphSeriesHide: (seriesTarget) => {
                let name = seriesTarget.target.name;
                let selector = domSelectors.propLegendName + ':contains(' + name + ')';
                let $target = $(selector);
                if ($target.length) {
                    this.hidePropLegend($target.closest(domSelectors.propLegend), false, true);
                }
            }
        }, graphOpts);
        if ($ratePerCompGraph) {
            infinito.components.ratePerCompGraph.reflow($ratePerCompGraph, joinedOpts);
        } else {
            $ratePerCompGraph = infinito.components.ratePerCompGraph.render(joinedOpts);
        }
    }

    fillRatePaceGraph(scopedOpts) {
        let date = new Date(this.mastermonthselected);
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        firstDay = this.formatedate(firstDay);
        lastDay = this.formatedate(lastDay);
        if (this.mastermonthselected == nonformatedmonth[0]) {
            firstDay = infinito.vao.controller.storageHelper.getCurrentDate();
        }

        scopedOpts = $.extend({}, scopedOpts, {
            firstStayDate: firstDay,
            lastStayDate: lastDay,
            callfrom: 'desktop'
        });
        let joinedOpts = $.extend({}, {
            data: pageData,
            hotelname: this.hotelname,
            colorArray: colorArray,
            getRateColor: this.obtainPropNameColorAndAddToLegendIfNotExist,
            container: domSelectors.ratePaceGraphContainer,
            ratePaceGraphLegendEnabled: true
        }, scopedOpts);
        if ($ratePaceGraph) {
            infinito.components.ratePaceGraph.reflow($ratePaceGraph, joinedOpts);
        } else {
            $ratePaceGraph = infinito.components.ratePaceGraph.render(joinedOpts);
        }
    }

    obtainPropNameColorAndAddToLegendIfNotExist(propName) {
        let color = propNameToColorMap[propName] || null;
        if (!color) {
            let temp = colorArray[colorIndexCounter];
            colorIndexCounter += 1;
            propNameToColorMap[propName] = temp;
            let color = propNameToColorMap[propName] || null;
            let $propLegend;

            if (
                !color
            ) {
                // should have already been set before calling this
                // @see obtainPropNameColorAndAddToLegendIfNotExist()
                return;
            }

            $propLegend = $($(domSelectors.propLegendTemplate)
                .html());
            $propLegend.find(domSelectors.propLegendDot)
                .css('background', color);
            $propLegend.find(domSelectors.propLegendName)
                .text(propName);

            $(domSelectors.prefaceLegendContainer)
                .append($propLegend);
        }

        return color;
    }

    fillPreface(opts) {
        if ((opts || {}).shouldSkipFilter) {
            // skip the filter init by returning resolved promise
        } else {
            this.fillPrefaceFilter(opts);
        }
    }

    fillPrefaceFilter(opts) {
        $filterPopup = infinito.components.rateFilterPopup.render($.extend({}, {
            container: domSelectors.page,
            attachTo: 'body',
            trigger: 'click',
            placement: 'bottom',
            startOnSelectDate: (newStartDate) => {
                state = $.extend({}, state, {
                    firstStayDate: newStartDate,
                    startDefault: newStartDate
                });
                this.coreEnter(state, true);
            },
            rangeOnChange: () => {
                state = $.extend({}, state, {
                    range: 30,
                    rangeCurrent: 30
                });
                this.fillRateGraph(state);
                this.fillRatePaceGraph(state);
            },
            channelChannels: infinito.vao.controller.channelHelper.getChannels(),
            channelCurrentVal: (opts || {}).channelsId || infinito.vao.controller.channelHelper.getPrimaryChannelsId(),
            channelOnChange: (newChannel) => {
                state = $.extend({}, state, {
                    channelsId: newChannel
                });
                this.cleanupPageStatComponents();
                this.coreEnter(state);
            },
            occupancyOnChange: () => {
            },
            mealOnChange: () => {
            },
            depositOnChange: () => {
            }
        }, opts));
        state.shouldSkipFilter = true;
    }

    emptyPrefaceLegends() {
        $(domSelectors.prefaceLegendContainer)
            .empty();
    }

    formatedate(dt) {
        let date = new Date(dt);

        let d = date.getDate();
        let m = date.getMonth() + 1;
        let y = date.getFullYear();

        return y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    async fetchPageData(opts) {
        return new Promise((resolve, reject) => {
            try {
                this.mastermonthselected = $('#vao__select--month')
                    .val();
                let date = new Date(this.mastermonthselected);
                let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                firstDay = this.formatedate(firstDay);
                lastDay = this.formatedate(lastDay);
                let scopedOpts = opts || state;

                let currentDate = infinito.vao.controller.storageHelper.getCurrentDate();
                let fields = infinito.vao.model.biStatistics.fields;
                infinito.vao.model.biStatistics.fetchStatistics(this.hotelid, infinito.vao.model.biStatistics.buildQuery({
                        recordDate: currentDate,
                        firstStayDate: firstDay,
                        lastStayDate: lastDay,
                        fields: [fields.competitors, fields.marketOccupancyForecast, fields.occupancy, fields.allRatesPickup, fields.rateLeadSiblingPace, fields.compSetAvgLeadSiblingPace],
                        ratesChannelsIds: [((scopedOpts || {}).channelsId || infinito.vao.controller.channelHelper.getPrimaryChannelsId())],
                        pickupOffset: infinito.vao.controller.storageHelper.getPickupOffset()
                    }),
                    (rawData, processedData) => {
                        pageData = processedData || {};
                        resolve(pageData);
                    });
            } catch (e) {
                reject(e);
            }
        });
    }
}


window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageDesktop = pageMonthlyAnalysisModal;
customElements.define('vao-page-monthlyanalysis-modal', pageMonthlyAnalysisModal);
