import { LitElement } from 'lit-element';
export * from 'lit-element';

let count = 0;

export class AppElement extends LitElement {

    static getUniqueElementId() {
        count++;
        return '__vao-appelement__' + count;
    }

    createRenderRoot() {
        // do not render into the shadow-root so that we use global style sheet
        return this;
    }

    reflowBool(ref, newProp) {
        const strNewProp = String(newProp);
        if (strNewProp === 'true' || strNewProp === 'false') {
            return strNewProp;
        }
        return ref;
    }

}
