import { AppElement, html } from '../AppElement.js';

export const MODE_GROUP = 'group';
export const MODE_SINGLE = 'single';

export default class PageRules extends AppElement {
    static get properties() {
        return {
            mode: { type: String },
            hotelOrGroup: { type: Object },
            recordDate: { type: String },
            pickupSetting: { type: Number }
        };
    }

    constructor(props = {}) {
        super();
        this.mode = props.mode
            || this.mode
            || window.infinito.vao.controller.storageHelper.getAppMode();
        this.hotelOrGroup = props.hotelOrGroup
            || this.hotelOrGroup
            || (this.mode === MODE_SINGLE
                    ? window.infinito.vao.controller.storageHelper.getSelectedHotel()
                    : window.infinito.vao.controller.storageHelper.getSelectedGroup()
            );
        this.recordDate = props.recordDate
            || this.recordDate
            || window.infinito.vao.controller.storageHelper.getCurrentDate();
        this.pickupSetting = $.isNumeric(props.pickupSetting)
            ? props.pickupSetting
            : ($.isNumeric(this.pickupSetting)
                    ? this.pickupSetting
                    : window.infinito.vao.controller.storageHelper.getPickupOffset()
            );
    }

    reflow(props = {}) {
        this.mode = props.mode || this.mode;
        this.hotelOrGroup = props.hotelOrGroup || this.hotelOrGroup;
        this.recordDate = props.recordDate || this.recordDate;
        this.pickupSetting = $.isNumeric(props.pickupSetting) ? props.pickupSetting : this.pickupSetting;
    }

    renderSingle() {
        return html`
            <vao-page-rules-single
                .hotel=${this.hotelOrGroup}
                .recordDate="${this.recordDate}"
                .pickupSetting="${this.pickupSetting}"
            ></vao-page-rules-single>
        `;
    }

    renderGroup() {
        /*return html`
            <vao-page-desktop-group
                .group=${this.hotelOrGroup}
                .recordDate="${this.recordDate}"
                .pickupSetting="${this.pickupSetting}"
            ></vao-page-desktop-group>
        `;*/
    }
    render() {
        return html`
<div class="vao__components--pageDesktop">
    ${this.mode === MODE_GROUP ? this.renderGroup() : this.renderSingle()}
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.PageRules = PageRules;
customElements.define('vao-page-rule', PageRules);
