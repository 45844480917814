import {AppElement, html} from '../AppElement.js';
import TableColumn, {columnSortStates} from "./TableColumn";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import Constants from "../Constants";
import TableLoadingRow from "./TableLoadingRow";
import TableNoDataRow from "./TableNoDataRow";
import ColorArray from "../ColorArray";
import Button from "../button/Button";
import TableTotalRow from "./TableTotalRow";
import {EVENT_ON_TABLE_COLUMN_VISIBILITY_CHANGE} from "./TableColumnVisibilityContent";
import {isJSONString} from "../Utils";

export const tableDensityTypes = {
    compact: 'compact',
    normal: 'normal',
    large: 'large'
};

export const tableAlignTypes = {
    left: 'left',
    center: 'center',
    right: 'right'
};

export const tableTheadStyles = {
    none: '',
    light: 'thead-light',
    dark: 'thead-dark'
};

export const tableExportFormats = {
    csv: 'csv'
};

export const tableExportEmptyCSSClass = 'tableexport-empty';
export const totalRowCSSClass = 'vao__components--table-total-row';

const defaultTableOpts = {
    canShowToolbar: true,
    canShowToolbarFilters: true,
    canShowToolbarActions: true,
    isBordered: true,
    isStripedRows: false,
    isSmallFont: true,
    isFixedFirstColumn: true,
    isStickyHeader: false,
    stickyHeaderMinHeight: undefined,
    isColoredColumns: false,
    isSwappedAxis: false,
    paddingType: tableDensityTypes.normal,
    align: tableAlignTypes.center,
    theadStyle: tableTheadStyles.none,
    tableExportOpts: {
        formats: [tableExportFormats.csv],
        exportButtons: false
    }
};

const globalStateTableOptKeys = [
    'isBordered',
    'isStripedRows',
    'isSmallFont',
    'isColoredColumns',
    'paddingType',
    'align',
    'theadStyle',
];

// Global state: State shared across all tables in the app.
// Element state: State only shared by a particular state key from an element.
const statePrototype = {
    tableOpts: {},
    hiddenColumns: []
};

export const EVENT_TABLE_TABLEOPTS_CHANGE = 'vao-table-tableopts-change';
export const EVENT_TABLE_GLOBAL_STATE_CHANGE = 'vao-table-state-global-change';
export const EVENT_TABLE_ELEMENT_STATE_CHANGE = 'vao-table-state-element-change';

let globalStateSuffix = null;

export default class Table extends AppElement {
    static get properties() {
        return {
            tableOpts: { type: Object },
            columns: { type: Array },
            rows: { type: Array },
            stateKey: { type: String },
        };
    }

    static registerGlobalStateSuffix(suffix) {
        globalStateSuffix = suffix;
    }

    static getDefaultTableOpts() {
        return Object.assign(
            {},
            defaultTableOpts,
            (Table.getGlobalState().tableOpts || {})
        );
    }

    constructor(props = {}) {
        super();
        this.tableOpts = Object.assign(
            {},
            defaultTableOpts,
            (this.getGlobalState().tableOpts || {}),
            (props.tableOpts || {}),
            {
                tableExportOpts: Object.assign(
                    {},
                    defaultTableOpts.tableExportOpts,
                    (this.getGlobalState().tableOpts || {}).tableExportOpts,
                    (props.tableOpts || {}).tableExportOpts,
                )
            }
        );

        this.columns = props.columns || [];
        this.rows = props.rows || [];
        this.stateKey = props.stateKey;

        this.id = AppElement.getUniqueElementId();
        this.leafColumnFields = new Set(); // Needs to be calculated in-case input is complex columns.
        this.tableExport = null;
        this.columnColorArray = new ColorArray();
        this.moneyHelper = window.infinito.vao.controller.moneyHelper;
        this.addEventListener(EVENT_ON_TABLE_COLUMN_VISIBILITY_CHANGE, this.handleTableColumnVisibilityChange);
    }

    reflow(props = {}) {
        this.tableOpts = Object.assign({}, this.tableOpts, props.tableOpts || {});
        this.columns = props.columns || this.columns;
        this.rows = props.rows || this.rows;
    }

    getElementState() {
        if (!this.canUseElementState()) {
            return JSON.parse(JSON.stringify(statePrototype));
        }
        const key = this.getElementStateKey();
        const item = window.localStorage.getItem(key);
        if (item && isJSONString(item)) {
            return JSON.parse(item);
        }
        return JSON.parse(JSON.stringify(statePrototype));
    }

    setElementState(state) {
        if (!this.canUseElementState()) {
            return;
        }
        const key = this.getElementStateKey();
        const item = JSON.stringify(state);
        window.localStorage.setItem(key, item);
        const event = new CustomEvent(EVENT_TABLE_ELEMENT_STATE_CHANGE, {
            detail: {
                key: key,
                newState: state
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    static getGlobalState() {
        if (!Table.canUseGlobalState()) {
            return JSON.parse(JSON.stringify(statePrototype));
        }
        const key = Table.getGlobalStateKey();
        const item = window.localStorage.getItem(key);
        if (item && isJSONString(item)) {
            return JSON.parse(item);
        }
        return JSON.parse(JSON.stringify(statePrototype));
    }

    static getGlobalStateKey() {
        return `vao-table-state-global_${globalStateSuffix}`;
    }

    static canUseGlobalState() {
        return globalStateSuffix && typeof globalStateSuffix === 'string' && globalStateSuffix.length > 0;
    }

    getGlobalState() {
        return Table.getGlobalState();
    }

    setGlobalState(state) {
        if (!this.canUseGlobalState()) {
            return;
        }
        const key = this.getGlobalStateKey();
        const item = JSON.stringify(state);
        window.localStorage.setItem(key, item);
        const event = new CustomEvent(EVENT_TABLE_GLOBAL_STATE_CHANGE, {
            detail: {
                key: key,
                newState: state
            },
            bubbles: true
        });
        this.dispatchEvent(event);
    }

    getElementStateKey() {
        return `vao-table-state-element_${this.stateKey}`;
    }

    getGlobalStateKey() {
        return Table.getGlobalStateKey();
    }

    canUseElementState() {
        return this.stateKey && typeof this.stateKey === 'string' && this.stateKey.length > 0;
    }

    canUseGlobalState() {
        return Table.canUseGlobalState();
    }

    updateColumnVisibilityState() {
        let hiddenColumns = [];
        TableColumn.iterate(this.columns, (column) => {
            if (!column.isVisible) {
                hiddenColumns.push(column.tableCell.field);
            }
        });
        this.setElementState({
            ...this.getElementState(),
            hiddenColumns
        });
    }

    updateGlobalStateTableOpts() {
        const tableOpts = this.tableOpts;
        if (!tableOpts || typeof tableOpts !== 'object') {
            return;
        }
        let didFindChange = false;
        const state = {...this.getGlobalState()};
        state.tableOpts = state.tableOpts || {};
        globalStateTableOptKeys.forEach(globalStateTableOptKey => {
            if (
                globalStateTableOptKey in tableOpts
                && tableOpts[globalStateTableOptKey] !== state.tableOpts[globalStateTableOptKey]
            ) {
                didFindChange = true;
                state.tableOpts[globalStateTableOptKey] = tableOpts[globalStateTableOptKey];
            }
        });
        if (didFindChange) {
            this.setGlobalState(state);
        }
    }

    matchColumnVisibilityWithState() {
        const state = this.getElementState();
        const hiddenColumns = state.hiddenColumns;
        if (hiddenColumns.length === 0) {
            return;
        }
        TableColumn.iterate(this.columns, (column) => {
            const field = column.tableCell.field;
            if (hiddenColumns.includes(field)) {
                column.isVisible = false;
            } else {
                column.isVisible = true;
            }
        });
    }

    handleTableColumnVisibilityChange(e) {
        const col = e.detail.column;
        if (!col || !this.columns) {
            return;
        }
        col.isVisible = !col.isVisible;
        this.columns = [...this.columns]; // Re-render.
        this.updateColumnVisibilityState();
    }

    makeTableExportOpts() {
        let hotelname = window.infinito.vao.controller.storageHelper.getSelectedHotel();
        let exportFilename = this.tableOpts.tableExportOpts.filename ||
            'table_' + Math.round((new Date()).getTime() / 1000);
        // let exportFilename = hotelname.name+'_'+Math.round((new Date()).getTime() / 1000);

        // $(".vao__components--button").on('click',function(event){
        //     console.log(event);
        // });


        return {
            ...this.tableOpts.tableExportOpts,
            filename: exportFilename
        };
    }


    removeTableExport() {
        if (this.hasInitializedTableExport()) {
            this.tableExport.remove();
        }
        this.tableExport = null;
    }

    hasInitializedTableExport() {
        return this.tableExport && this.tableExport instanceof window.TableExport;
    }

    handleInitTableExportAdditionalEmptyCssClasses() {
        const exportOpts = this.makeTableExportOpts();
        if (
            'additionalEmptyCSS' in exportOpts
            && (
                typeof exportOpts.additionalEmptyCSS === 'string'
                || Array.isArray(exportOpts.additionalEmptyCSS)
            )
        ) {
            let emptyExportCssClasses;
            if (typeof exportOpts.additionalEmptyCSS === 'string') {
                emptyExportCssClasses = [exportOpts.additionalEmptyCSS];
            } else {
                emptyExportCssClasses = [...exportOpts.additionalEmptyCSS];
            }
            $('#' + this.id).find('.' + tableExportEmptyCSSClass).removeClass(tableExportEmptyCSSClass);
            emptyExportCssClasses.forEach(additionalEmptyCss => {
                $('#' + this.id).find(additionalEmptyCss).addClass(tableExportEmptyCSSClass);
            });
        }
    }

    initTableExport() {
        if (this.tableOpts.canShowToolbarActions && window.TableExport) {
            if (this.hasInitializedTableExport()) {
                return;
            }
            let selectors = document.querySelector('#' + this.id + ' table');
            let exportOpts = this.makeTableExportOpts();
            if (!selectors || !exportOpts || typeof exportOpts !== 'object') {
                return;
            }
            this.handleInitTableExportAdditionalEmptyCssClasses();
            this.tableExport = new window.TableExport(
                selectors,
                exportOpts
            );
        } else {
            this.removeTableExport();
        }
    }

    handleDownloadCsvClick() {
        this.removeTableExport();
        this.initTableExport();
        if (this.hasInitializedTableExport()) {
            this.tableExport.reset();
            let exportData = this.tableExport.getExportData();
            if (typeof exportData === 'object') {
                Object.keys(exportData).some((key) => {
                    let tableData = exportData[key];
                    if ('csv' in tableData) {
                        var csvData = tableData.csv;
                        var hotel = window.infinito.vao.controller.storageHelper.getSelectedHotel();
                        const formatted = window.infinito.vao.controller.moneyHelper.formatMoneyBracketStyle(
                            "1",
                            Constants.REVENUE_DIGITS,
                            true,
                            hotel.locale
                        );
                        var currencySymbol = formatted.substr(0,formatted.length-1);
                        // This code will work for most cases.
                        // If we get a complaint that we're removing substrings from the CSV export this will likely be the cause.
                        // A more robust solution using RegExp is needed to match nubmers prefixed by a symbol in that case.
                        // This will have to account for cases where we have special characters like `$` that have to be escaped for RegExp or a string like `Rp`.
                        // You will likely need something like lodash's escapeRegExp to build your regex reliabely in that case.
                        this.tableExport.export2file(
                            csvData.data.replaceAll(currencySymbol, ''),
                            csvData.mimeType,
                            csvData.filename,
                            csvData.fileExtension,
                            csvData.merges,
                            csvData.RTL,
                            csvData.sheetname
                        );
                    }
                    return true;
                });
            }
        }
    }

    handleDownloadxlsxClick(){
        let uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><title></title><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            base64 = function(s) { return window.btoa(decodeURIComponent(encodeURIComponent(s))) },         format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; })}
        let name = 'excelworkbook_';
        let filename = 'sample.xls';
        var table = $(".vao__components--table-table")[0];
        var ctx = {worksheet: name || 'Worksheet', table: table.innerHTML}

        var link = document.createElement('a');
        link.download = filename;
        link.href = uri + base64(format(template, ctx));
        link.click();
    }

    makeTableClass() {
        return `
        vao__components--table-table
        table 
        nowrap 
        ${this.tableOpts.isBordered ? 'table-bordered' : ''} 
        ${this.tableOpts.isStripedRows ? 'table-striped' : ''} 
        ${this.tableOpts.paddingType === tableDensityTypes.compact ? 'table-sm' : ''}
        ${this.tableOpts.paddingType === tableDensityTypes.normal ? 'table-md' : ''}
        ${this.tableOpts.align === tableAlignTypes.left ? 'table-align-left' : ''}
        ${this.tableOpts.align === tableAlignTypes.center ? 'table-align-center' : ''}
        ${this.tableOpts.align === tableAlignTypes.right ? 'table-align-right' : ''}
        ${this.tableOpts.isSmallFont ? 'table-fs-small' : ''}
        ${this.tableOpts.isFixedFirstColumn ? 'table-static-left' : ''}
        ${this.tableOpts.isStickyHeader ? 'table-sticky-top' : ''}
        `;
    }

    resetColumnSortStates(columnToSkip) {
        const recursiveColumnIterator = (columns) => {
            columns.forEach(column => {
                if (
                    !(column instanceof TableColumn)
                    || column === columnToSkip
                ) {
                    return;
                }
                column.resetSortState();
                if (column.children.length > 0) {
                    recursiveColumnIterator(column.children);
                }
            });
        };
        recursiveColumnIterator(this.columns);
    }

    getSortedLeafColumn() {
        let sortedLeafColumn = null;
        const recursiveColumnIterator = (columns) => {
            if (sortedLeafColumn) {
                return;
            }
            columns.forEach(column => {
                if (sortedLeafColumn) {
                    return;
                }
                if (!(column instanceof TableColumn)) {
                    return;
                }
                if (column.hasSortApplied()) {
                    sortedLeafColumn = column;
                    return;
                }
                if (column.children.length > 0) {
                    recursiveColumnIterator(column.children);
                }
            });
        };
        recursiveColumnIterator(this.columns);
        return sortedLeafColumn;
    }

    getSortableValueFrom(text) {
        if (typeof text === 'string') {
            text = text.trim();
            if (text.length > 0) {
                const words = text.split(' ');
                const firstWord = words[0];
                const isAllWordsHaveNumber = words.every((word) => {
                    return /\d/.test(word);
                });
                if (firstWord.includes('%')) {
                    return parseFloat(firstWord);
                }
                if (isAllWordsHaveNumber) {
                    let firstWordNum = this.moneyHelper.unformatMoneyBracketStyle(firstWord);
                    if (Number.isFinite(firstWordNum)) {
                        return firstWordNum;
                    }
                }
                return text;
            }
        }
        return null;
    }

    getSortableRowValue(tableRow, columnLeaf) {
        if (tableRow instanceof TableRow) {
            const tableCell = tableRow.tableCellPerField.get(columnLeaf);
            if (tableCell instanceof TableCell) {
                const tableCellSortableValue = tableCell.getSortableValue();
                // When the caller provides an implicit sortable value, use it.
                if (tableCellSortableValue !== null) {
                    return tableCellSortableValue;
                }
                let inferredSortableValue;
                // Try just use the value property.
                inferredSortableValue = this.getSortableValueFrom(tableCell.value);
                if (inferredSortableValue !== null) {
                    return inferredSortableValue;
                }
                // Lastly, just have a go and attempt to automatically deduce the sortable value. Keep in mind that
                // the lit-html render process occurs before DOM changes.
                inferredSortableValue = this.getSortableValueFrom($(tableCell).text());
                if (inferredSortableValue !== null) {
                    return inferredSortableValue;
                }
            }
        }
        return null;
    }

    makeSortedRows() {
        const sortLeafColumn = this.getSortedLeafColumn();
        if (sortLeafColumn instanceof TableColumn) {
            return [...this.rows].sort((a, b) => {
                // Always ensure TableTotalRow is at the bottom.
                if (a instanceof TableTotalRow) {
                    return 1;
                }
                if (b instanceof TableTotalRow) {
                    return -1;
                }
                // Compare.
                const comparableA = this.getSortableRowValue(a, sortLeafColumn.tableCell.field);
                const comparableB = this.getSortableRowValue(b, sortLeafColumn.tableCell.field);
                if (comparableA === null && comparableB === null) {
                    return 0;
                }
                const isComparableAString = typeof comparableA === 'string';
                const isComparableBString = typeof comparableB === 'string';
                const comparableAFloat = parseFloat(comparableA) || 0;
                const comparableBFloat = parseFloat(comparableB) || 0;
                if (sortLeafColumn.isSortAscending()) {
                    if (isComparableAString && isComparableBString) {
                        return comparableA.localeCompare(comparableB);
                    }
                    if (isComparableAString && comparableB === null) {
                        return 1;
                    }
                    if (isComparableBString && comparableA === null) {
                        return -1;
                    }
                    if (comparableAFloat < comparableBFloat) {
                        return -1;
                    }
                    if (comparableAFloat > comparableBFloat) {
                        return 1;
                    }
                } else if (sortLeafColumn.isSortDescending()) {
                    if (isComparableAString && isComparableBString) {
                        return comparableB.localeCompare(comparableA);
                    }
                    if (isComparableAString && comparableB === null) {
                        return -1;
                    }
                    if (isComparableBString && comparableA === null) {
                        return 1;
                    }
                    if (comparableAFloat < comparableBFloat) {
                        return 1;
                    }
                    if (comparableAFloat > comparableBFloat) {
                        return -1;
                    }
                }
                return 0;
            });
        }
        return this.rows;
    }

    // A global solution to invert all X & Y axis is complicated. There could be many
    // different input configurations with setups to have different rowspan and colspan
    // attributes on each cell. For this reason, only allow inversion (swapping) on
    // simple and testable table configurations for now. Additionally - making the inversion
    // work for all other table settings such as static left column, zebra coloring, etc
    // can produce un-desired side effects.
    canRenderSwappedMode() {
        if (
            !Array.isArray(this.columns)
            || this.columns.length === 0
            || !Array.isArray(this.rows)
            || this.rows.length === 0
            || this.hasComplexTableColumn()
            || this.hasComplexTableRow()
        ) {
            return false;
        }

        return true;
    }

    hasComplexTableColumn() {
        return this.columns.some(col => {
            if (col instanceof TableColumn) {
                return col.children.length > 0;
            }
            return true; // Default to true complexity, expect input columns to be a TableColumn instance.
        });
    }

    hasComplexTableRow() {
        return this.rows.some(row => {
            if (row instanceof TableRow) {
                const tableCells = Array.from(row.tableCellPerField.values());
                return tableCells.some(cell => {
                    if (cell instanceof TableCell) {
                        const colspan = cell.colSpan;
                        const rowspan = cell.rowSpan;
                        if (colspan > 1 || rowspan > 1) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            return true; // Default to true complexity. Complex here may indicate a TableLoading row etc...
        });
    }

    updated(_changedProperties) {
        super.updated(_changedProperties);
        if (_changedProperties.has('tableOpts')) {
            this.updateGlobalStateTableOpts();
            const event = new CustomEvent(EVENT_TABLE_TABLEOPTS_CHANGE, {
                detail: {
                    oldTableOpts: _changedProperties.get('tableOpts'),
                    newTableOpts: this.tableOpts
                },
                bubbles: true
            });
            this.dispatchEvent(event);
        }
        this.renderColumnBorderBottomStyles();
    }

    renderCsvDownloadBtn() {
        return html`
        <vao-button
            tooltip="Download CSV"
            variant="primary"
            size="small"
            color="light"
            startIcon="${Constants.ICONS.CSV}"
            @click="${this.handleDownloadCsvClick}">
        </vao-button>`;
    }


    determineColumnColSpan(column) {
        if (column.children.length > 0) {
            return column.children.reduce((accumulator, currentValue) => {
                if (currentValue.isVisible === true) {
                    return accumulator + 1;
                }
                return accumulator;
            }, 0);
        }
        return column.tableCell.colSpan;
    }

    renderHeadNormal() {
        let headObj = {};
        if (!Array.isArray(this.columns) || this.columns.length === 0) {
            return '';
        }
        let recurse = (cols, depth, colorGroupKey) => {
            cols.forEach((col, index) => {
                if (!(col instanceof TableColumn) || !(col.tableCell instanceof TableCell)) {
                    return;
                }
                if (col.isVisible !== true) {
                    return;
                }
                let _colorGroupKey = col.colGroup || colorGroupKey ||  'na_' + depth + '_' + index;
                let shouldCol = false;
                let isSortable = false;
                let colSortState = columnSortStates.none;
                if (col.children.length > 0) {
                    recurse(col.children, depth + 1, _colorGroupKey);
                } else {
                    this.leafColumnFields.add(col.tableCell.field);
                    _colorGroupKey = 'table-column-col-group-' + _colorGroupKey;
                    this.columnColorArray.next(_colorGroupKey);
                    shouldCol = true;
                    if (col.isSortable) {
                        isSortable = true;
                        colSortState = col.sortState;
                    }
                }
                headObj[depth] = headObj[depth] || [];

                let colCls = shouldCol ? _colorGroupKey : '';
                let fieldCls = 'table-cell-' + (col.tableCell.field);
                let sortEl = null;
                if (isSortable) {
                    let sortIcon;
                    let sortColor = 'light';
                    if (colSortState === columnSortStates.none) {
                        sortIcon = Constants.ICONS.SORT;
                    } else if (colSortState === columnSortStates.ascending) {
                        sortIcon = Constants.ICONS.SORT_DOWN;
                        sortColor = 'primary';
                    } else if (colSortState === columnSortStates.descending) {
                        sortIcon = Constants.ICONS.SORT_UP;
                        sortColor = 'primary';
                    }
                    if (sortIcon) {
                        sortEl = new Button({
                            startIcon: sortIcon,
                            size: 'xxs',
                            variant: 'invert',
                            color: sortColor,
                            onClick: () => {
                                if (col instanceof TableColumn) {
                                    col.nextSortState();
                                    this.resetColumnSortStates(col);
                                    this.columns = [...this.columns]; // Re-render.
                                }
                            }
                        });
                    }
                }
                let columnEl;
                if (col.description) {
                    if (sortEl) {
                        columnEl = html`
<div class="vao__components--table-sortableColumn">
    <div class="vao__components--table-describedColumn">
        ${col.tableCell}
        <vao-icon cls="${Constants.ICONS.QUESTION_CIRCLED}" tooltip="${col.description}" style="margin-left:6px;">
        </vao-icon>
    </div>
    ${sortEl}
</div>`;
                    } else {
                        columnEl = html`
<div class="vao__components--table-describedColumn">
    ${col.tableCell}
    <vao-icon cls="${Constants.ICONS.QUESTION_CIRCLED}" tooltip="${col.description}" style="margin-left:6px;">
    </vao-icon>
</div>`;
                    }
                } else {
                    if (sortEl) {
                        columnEl = html`
<div class="vao__components--table-sortableColumn">
    ${col.tableCell}
    ${sortEl}
</div>`;
                    } else {
                        columnEl = html`${col.tableCell}`;
                    }
                }
                const colSpan = this.determineColumnColSpan(col);
                if (colSpan !== 0) {
                    headObj[depth].push(html`
<th 
scope="col" 
class="${fieldCls} ${colCls}" 
colspan="${colSpan}" 
rowspan="${col.tableCell.rowSpan}">
    ${columnEl}
</th>`);
                }
            });
        };
        recurse(this.columns, 0);
        let res = Object.values(headObj).map(val => {
            return html`<tr>${val}</tr>`;
        });
        return res;
    }

    // Swapped mode is when the user wants to invert the X and Y axis.
    // Assumes canRenderSwappedMode() called first.
    renderHeadSwapped() {
        const depth = 0;
        let headObj = {
            0: []
        };
        let col;
        let colGroupKey;
        let index = 0;
        let fieldCls;
        let colCls;

        // Primary column.
        col = this.columns[0];
        const primaryCol = col;
        this.leafColumnFields.add(col.tableCell.field);
        colGroupKey = 'table-column-col-group-' + (col.colGroup || 'na_' + depth + '_' + index);
        this.columnColorArray.next(colGroupKey);
        fieldCls = 'table-cell-' + (col.tableCell.field);
        colCls = colGroupKey;

        // Deliberately invert the rowspan and colspan.
        if (col.description) {
            headObj[0].push(html`
<th 
scope="col" 
class="${fieldCls} ${colCls}" 
colspan="${col.tableCell.rowSpan}" 
rowspan="${col.tableCell.colSpan}">
    <div class="vao__components--table-describedColumn">
        ${col.tableCell}
        <vao-icon cls="${Constants.ICONS.QUESTION_CIRCLED}" tooltip="${col.description}" style="margin-left:6px;">
        </vao-icon>
    </div>
</th>`);
        } else {
            headObj[0].push(html`
<th 
scope="col" 
class="${fieldCls} ${colCls}" 
colspan="${col.tableCell.rowSpan}" 
rowspan="${col.tableCell.colSpan}">
    ${col.tableCell}
</th>`);
        }

        // Subsequent columns. These now need to come from the primary columns row data.
        this.rows.forEach(row => {
            index++;
            const match = row.tableCellPerField.get(primaryCol.tableCell.field);
            if (match instanceof TableCell) {
                this.leafColumnFields.add(match.field);
                colGroupKey = 'table-column-col-group-' + 'na_' + depth + '_' + index;
                this.columnColorArray.next(colGroupKey);
                fieldCls = 'table-cell-' + (match.field);
                colCls = colGroupKey;
                headObj[0].push(html`
<th 
scope="col" 
class="${fieldCls} ${colCls}" 
colspan="1" 
rowspan="1">
    ${match}
</th>`);
            }
        });

        // Interpret the new thead.
        let res = Object.values(headObj).map(val => {
            return html`<tr>${val}</tr>`;
        });
        return res;
    }

    renderHead() {
        this.leafColumnFields = new Set();
        if (this.tableOpts.isSwappedAxis === true && this.canRenderSwappedMode()) {
            return this.renderHeadSwapped();
        }
        return this.renderHeadNormal();
    }

    renderBodyNormal() {
        let bodyObj = {};
        let rowClsMap = new Map();
        if (!Array.isArray(this.rows) || this.rows.length === 0) {
            return '';
        }
        const sortedRows = this.makeSortedRows();
        sortedRows.forEach((row, rowIndex) => {
            if (row instanceof TableLoadingRow) {
                bodyObj[rowIndex] = bodyObj[rowIndex] || [];
                this.leafColumnFields.forEach((columnLeaf) => {
                    let fieldCls = 'table-cell-' + (columnLeaf);
                    bodyObj[rowIndex].push(html`
<td class="${fieldCls}" rowspan="1" colspan="1">
    <vao-table-cell isLoading="true"></vao-table-cell>
</td>`);
                });
            } else if (row instanceof TableNoDataRow) {
                bodyObj[rowIndex] = bodyObj[rowIndex] || [];
                bodyObj[rowIndex].push(html`
<td class="table-no-data-field" rowspan="1" colspan="${this.leafColumnFields.size}">
    ${row}
</td>`);
            } else if (row instanceof TableRow) {
                let rowIndexKey = rowIndex + ''; // needs string to keep key consistent across obj and map
                bodyObj[rowIndexKey] = bodyObj[rowIndexKey] || [];
                rowClsMap.set(rowIndexKey, row.rowClasses);
                this.leafColumnFields.forEach((columnLeaf) => {
                    let cell = row.tableCellPerField.get(columnLeaf);
                    let fieldCls = 'table-cell-' + (columnLeaf);
                    let CellColor = cell!=undefined ? cell.__cellcolor : 'White';
                    if (cell instanceof TableCell) {
                        bodyObj[rowIndexKey].push(html`
<td class="${fieldCls}" rowspan="${cell.rowSpan}" colspan="${cell.colSpan}" style="background-color: ${CellColor}">
    ${cell}
</td>`);
                    } else {
                        bodyObj[rowIndexKey].push(html`
<td class="${fieldCls}" rowspan="1" colspan="1" style="background-color: ${CellColor}">
    ${Constants.MISSING_STR}
</td>`);
                    }
                });
            }
        });
        let res = Object.keys(bodyObj).map(key => {
            let val = bodyObj[key];
            let cls = rowClsMap.get(key) || '';
            return html`<tr role="row" class="${cls}">${val}</tr>`;
        });
        return res;
    }

    renderBodySwapped() {
        let bodyObj = {};
        let remainingCols = [...this.columns];
        let remainingColsLeafs = new Set();

        remainingCols.shift(); // Because primary already used in thead.

        let baseLength = 0;
        let recurse = (cols, depth) => {
            cols.forEach((col, index) => {
                let rowKey;
                let colKey = depth;

                if (depth === 0) {
                    baseLength = Object.keys(bodyObj).length;
                    rowKey = baseLength;
                } else {
                    rowKey = baseLength + index;
                }

                if (!(col instanceof TableColumn) || !(col.tableCell instanceof TableCell)) {
                    return;
                }
                if (col.isVisible !== true) {
                    return;
                }
                if (col.children.length > 0) {
                    recurse(col.children, depth + 1);
                } else {
                    remainingColsLeafs.add(col.tableCell.field);
                }

                bodyObj[rowKey] = bodyObj[rowKey] || [];

                let fieldCls = 'table-cell-' + (col.tableCell.field);

                // Deliberately inverse colspan and rowspan.
                if (col.description) {
                    bodyObj[rowKey][colKey] = html`
<td 
class="${fieldCls}" 
colspan="${col.tableCell.rowSpan}" 
rowspan="${col.tableCell.colSpan}">
    <div class="vao__components--table-describedColumn">
        ${col.tableCell}
        <vao-icon cls="${Constants.ICONS.QUESTION_CIRCLED}" tooltip="${col.description}" style="margin-left:6px;">
        </vao-icon>
    </div>
</td>`;
                } else {
                    bodyObj[rowKey][colKey] = html`
<td 
class="${fieldCls}" 
colspan="${col.tableCell.rowSpan}" 
rowspan="${col.tableCell.colSpan}">
    ${col.tableCell}
</td>`;
                }
            });
        };
        recurse(remainingCols, 0);

        if (!Array.isArray(this.rows) || this.rows.length === 0) {
            return '';
        }
        this.rows.forEach((row, rowIndex) => {
            let colLeafIndex = 0;
            if (row instanceof TableLoadingRow) {
                remainingColsLeafs.forEach((columnLeaf) => {
                    let fieldCls = 'table-cell-' + (columnLeaf);
                    bodyObj[colLeafIndex].push(html`
<td class="${fieldCls}" rowspan="1" colspan="1">
    <vao-table-cell isLoading="true"></vao-table-cell>
</td>`);
                    colLeafIndex++;
                });
            } else if (row instanceof TableNoDataRow) {
                bodyObj[rowIndex].push(html`
<td class="table-no-data-field" rowspan="${remainingColsLeafs.size}" colspan="1">
    ${row}
</td>`);
            } else if (row instanceof TableRow) {
                remainingColsLeafs.forEach((columnLeaf) => {
                    let cell = row.tableCellPerField.get(columnLeaf);
                    let fieldCls = 'table-cell-' + (columnLeaf);
                    if (cell instanceof TableCell) {
                        bodyObj[colLeafIndex].push(html`
<td class="${fieldCls}" rowspan="${cell.colSpan}" colspan="${cell.rowSpan}">
    ${cell}
</td>`);
                    } else {
                        bodyObj[colLeafIndex].push(html`
<td class="${fieldCls}" rowspan="1" colspan="1">
    ${Constants.MISSING_STR}
</td>`);
                    }
                    colLeafIndex++;
                });
            }
        });
        let res = Object.keys(bodyObj).map(key => {
            let val = bodyObj[key];
            return html`<tr role="row">${val}</tr>`;
        });
        return res;
    }

    renderBody() {
        if (this.tableOpts.isSwappedAxis === true && this.canRenderSwappedMode()) {
            return this.renderBodySwapped();
        }
        return this.renderBodyNormal();
    }

    renderColumnBorderBottomStyles() {
        if (this.tableOpts.isColoredColumns) {
            return Array.from(this.columnColorArray.dataPerKey.values()).map((colorObj, index) => {
                let selector = '#' + this.id + ' .' + colorObj.key;
                let css = 'border-bottom: 2px solid ' + colorObj.color + ';';
                if (index === 0) {
                    return html`
<style>
    ${selector} {${css}}
</style>
                    `;
                } else {
                    return html`
<style>
    ${selector} {${css}}
</style>
                    `;
                }
            })
        } else {
            return html``;
        }
    }

    getComponentStyle() {
        let style = '';
        if (
            this.tableOpts.stickyHeaderMinHeight
            && typeof this.tableOpts.stickyHeaderMinHeight === 'string'
            && this.tableOpts.stickyHeaderMinHeight.length > 0
            && !this.tableOpts.stickyHeaderMinHeight.includes(';')
        ) {
            style += ('--x-table-sticky-head-max-height: ' + this.tableOpts.stickyHeaderMinHeight);
        }
        return style;
    }

    handleTableFilterChange(e) {
        if (e && e.detail && e.detail.tableOpts) {
            this.tableOpts = e.detail.tableOpts;
        }
    }

    render() {
        this.matchColumnVisibilityWithState();
        return html`
<div class="vao__components--table" style="${this.getComponentStyle()}">
    ${this.tableOpts.canShowToolbar ? html`
        <div class="vao__components--table-toolbar">
        ${this.tableOpts.canShowToolbarActions
            ? html `
            <div class="vao__components--table-actions">
                <div class="vao__components--table-actionCsv" style="display: inline-block;">
                    ${this.renderCsvDownloadBtn()}
                </div>
                
            </div>`
            : html``
        }
        ${this.tableOpts.canShowToolbarFilters
            ? html `
            <vao-table-filters 
                .tableOpts=${this.tableOpts} 
                .columns=${this.columns}
                canRenderSwappedMode=${this.canRenderSwappedMode()}
                @vao-table-filters-change="${this.handleTableFilterChange}">
            </vao-table-filters>`
            : html``
        }
    </div>
    ` : ''}
    <div class="table-responsive ${this.tableOpts.isStickyHeader ? 'has-table-sticky-top' : ''}">
        <table class="${this.makeTableClass()}">
            <thead class="${this.tableOpts.theadStyle}">
                ${this.renderHead()}
                ${this.renderColumnBorderBottomStyles()}
            </thead>
            <tbody>
                ${this.renderBody()}
            </tbody>
        </table>
    </div>
</div>
        `;
    }
}

window.vao = window.vao || {};
window.vao.components = window.vao.components || {};
window.vao.components.Table = Table;
customElements.define('vao-table', Table);